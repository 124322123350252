import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "reactstrap";
import AddButton from "../../../Components/Common/AddButton";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import AddCandidatesForm from "../../../Components/Common/Forms/AddCandidatesForm";
import AddModal from "../../../Components/Common/Modal/AddModal";
import SearchTextBox from "../../../Components/Common/SearchTextBox";
import Toaster from "../../../Components/Common/Toaster";
import { activateDeactivateCandidates, addCandidates, deleteCandidates, getCandidates, getCandidatesTableColumnNames, getCircles, getElections, updateCandidates, getCirclesByElectionID } from "../../../store/actions";
import { BasicTable } from "../../Tables/DataTables/datatableCom";
import { columns } from "./DataTableColumns";

const labels = [
	{
		id: 1,
		labelName: 'Elections',
		fieldName: 'election-drop-down',
		name: 'ElectionID',
		value: 'ElectionID'
	},
	{
		id: 2,
		labelName: 'Circles',
		fieldName: 'circles-drop-down',
		name: 'CircleID',
		value: 'CircleID'
	},
	{
		id: 3,
		labelName: 'Candidate Name English',
		fieldName: 'text-box',
		name: 'FullNameEnglish',
		value: 'FullNameEnglish'
	},
	{
		id: 4,
		labelName: 'Candidate Name Arabic',
		fieldName: 'text-box',
		name: 'FullNameArabic',
		value: 'FullNameArabic'
	},
	{
		id: 5,
		labelName: 'Mobile Number',
		fieldName: 'mobile-number',
		name: 'MobileNumber',
		value: 'MobileNumber'
	},
	{
		id: 6,
		labelName: "Password",
		fieldName: "password",
		name: "Password",
		value: "Password",
	},
	{
		id: 7,
		labelName: 'Domain Name',
		fieldName: 'text-box',
		name: 'DomainName',
		value: 'DomainName'
	},
	{
		id: 8,
		labelName: 'Users Limit',
		fieldName: 'number-box',
		name: 'UsersLimit',
		value: 'UsersLimit'
	},
	{
		id: 9,
		labelName: "Profile",
		fieldName: "profile",
		name: "profile",

	},
	{
		id: 10,
		labelName: "make as Admin Booth",
		fieldName: "check-box",
		name: "admin booth",
		Value: "admin Booth"
	}

];

const CreateCandidates = () => {
	const { t, i18n } = useTranslation();
	document.title = t('KW-Elections | Create Candidates');
	const auth = JSON.parse(sessionStorage.getItem("auth"));
	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [show, setShow] = useState(false);
	const [showDelete, setShowDelete] = useState(false);
	const [deleteRow, setDeleteRow] = useState();
	const [candidates, setCandidates] = useState();
	const [classRow, setClassRow] = useState();
	const [isAddOrEdit, setIsAddOrEdit] = useState('isAdd');


	const { Candidates, isLoading, columnNames, candidateDeleted, Profile } = useSelector((state) => ({
		Candidates: state.Candidates.candidates,
		columnNames: state.Candidates.columnNames,
		isLoading: state.Candidates.isLoading,
		candidateDeleted: state.Candidates.candidateDeleted,
		Profile: state.Candidates.profile
	}));

	const handleClassesClicks = (value) => {
		setShow(true);
		setIsAddOrEdit(value);
		setClassRow({});
	}
	console.log('Profile: ', Profile);

	const onSubmitHandler = (e) => {
		e.preventDefault();
		const candidateObj = {};
		const currentDate = new Date();
		isAddOrEdit === 'isEdit' && (candidateObj['_id'] = candidates['_id']);
		isAddOrEdit === 'isEdit' && (candidateObj['IsDelete'] = candidates['IsDelete']);
		isAddOrEdit === 'isEdit' && (candidateObj['IsActive'] = candidates['IsActive']);
		candidateObj['CircleID'] = candidates.CircleID
		candidateObj['FullNameEnglish'] = candidates.FullNameEnglish;
		candidateObj['FullNameArabic'] = candidates.FullNameArabic;
		candidateObj['ElectionID'] = candidates.ElectionID;
		candidateObj['DomainName'] = candidates.DomainName;

		if (candidates?.MobileNumber.includes('+965')) {
			candidateObj['MobileNumber'] = candidates.MobileNumber;
		} else {
			candidateObj['MobileNumber'] = '+965' + candidates.MobileNumber;
		}

		if (candidates?.Password) {
			candidateObj["Password"] = candidates.Password;
		}

		candidateObj['UsersLimit'] = candidates.UsersLimit;
		candidateObj['Profile'] = Profile?.Url;

		candidateObj['IsDelete'] = false;
		candidateObj['IsActive'] = true;
		candidateObj['CreatedBy'] = auth.id;
		candidateObj['ModifiedBy'] = auth.id;
		candidateObj['CreatedDate'] = currentDate.toISOString().slice(0, 10);
		candidateObj['ModifiedDate'] = currentDate.toISOString().slice(0, 10);
		candidateObj['admin_booth'] = candidates.admin_booth

		setTimeout(() => {
			isAddOrEdit === 'isAdd' ?
				(
					dispatch(addCandidates(candidateObj))
				) :
				dispatch(updateCandidates(candidateObj));
		}, 2200)

		setShow(false);
	}

	const onSelectHandler = (e) => {
		dispatch(getCirclesByElectionID({ ElectionID: e.target.value }))
		setCandidates((preValue) => ({
			...preValue,
			[e.target.name]: e.target.value
		}))
	}

	// const onChangeHandler = (e) => {
	// 	const value = e.target.type === 'checkbox' ? (e.target.checked ? "on" : "off") : e.target.value;
	// 	setCandidates((prevValue) => ({
	// 		...prevValue,
	// 		[e.target.name]: value
	// 	}));
	// };

	const onChangeHandler = (e) => {
		const { name, value, files } = e.target;
		if (files && files.length > 0) {
			setCandidates((prevData) => ({
				...prevData,
				[name]: files[0]
			}));
		} else {
			setCandidates((prevData) => ({
				...prevData,
				[name]: value
			}));
		}

		console.log("users", candidates);
	};


	const onActiveOrDeactiveChange = (candidates, e) => {
		const candidateObj = {}
		candidateObj['_id'] = candidates._id;
		candidateObj['IsActive'] = candidates.IsActive;
		candidateObj['TableName'] = 'Candidates';
		dispatch(activateDeactivateCandidates(candidateObj))
	}

	const onEditClickHandler = (candidates, value) => {
		dispatch(getCirclesByElectionID({ ElectionID: candidates.ElectionID }))
		setClassRow(candidates);
		setCandidates(candidates)
		setIsAddOrEdit(value);
		setShow(true);
	}

	const onDeleteClickHandler = candidates => {
		setShowDelete(true);
		setDeleteRow(candidates);
	}

	const onDeleteClick = () => {
		const x = dispatch(deleteCandidates({ _id: deleteRow._id }));
		setShowDelete(false);

		setTimeout(() => {
			dispatch(getCandidates());
		}, 2000)

	}


	useEffect(() => {
		dispatch(getElections());
		dispatch(getCircles());
		dispatch(getCandidates());
		dispatch(getCandidatesTableColumnNames());
	}, [dispatch]);

	useEffect(() => {
		setData(Candidates)
	}, [Candidates]);

	return (
		<React.Fragment>
			<Toaster />
			<div className="page-content">
				<Container fluid>
					<Row className='mb-3'>
						<Col>
							<BreadCrumb title={t('Create Candidate')} />
						</Col>
					</Row>
					<Row className='mb-3'>
						<Col>
							<AddButton handleClassesClicks={handleClassesClicks} />
						</Col>
						<Col>
							<SearchTextBox initialData={Candidates} setData={setData} />
						</Col>
					</Row>
					<Row>
						<Col>
							{isLoading ?
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<Spinner style={{
										height: '3rem',
										width: '3rem',
									}} className='me-2'> Loading... </Spinner>
								</div> :
								<BasicTable data={data} columns={columns(columnNames, i18n, t, onEditClickHandler, onDeleteClickHandler, onActiveOrDeactiveChange)} />}
						</Col>
					</Row>
				</Container>
			</div>


			<AddModal
				show={show}
				setShow={setShow}
				onSubmitHandler={onSubmitHandler}
				title={isAddOrEdit === 'isAdd' ? t('Add New Candidate') : t('Edit Candidate')}
				modalBody=
				{
					<AddCandidatesForm
						onSelectHandler={onSelectHandler}
						onChangeHandler={onChangeHandler}
						labels={labels}
						show={show}
						classRow={classRow}
						isAddOrEdit={isAddOrEdit}
					/>
				}
			/>

			<DeleteModal showDelete={showDelete} setShowDelete={setShowDelete} onDeleteClick={onDeleteClick} />
		</React.Fragment>
	)
}

export default CreateCandidates;