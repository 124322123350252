export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_ADD_USER = "GET_ROLES_ADD_USER"
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_ADD_USER_SUCCESS = "GET_ROLES_ADD_USER_SUCCESS"
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";
export const GET_ROLES_ADD_USER_FAIL = "GET_ROLES_ADD_USER_FAIL"


export const GET_ROLES_TABLE_COLUMN_NAMES = "GET_ROLES_TABLE_COLUMN_NAMES"
export const GET_ROLES_TABLE_COLUMN_NAMES_SUCCESS = "GET_ROLES_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_ROLES_TABLE_COLUMN_NAMES_FAIL = "GET_ROLES_TABLE_COLUMN_NAMES_FAIL"

export const ADD_ROLES = "ADD_ROLES"
export const ADD_ROLES_SUCCESS = "ADD_ROLES_SUCCESS"
export const ADD_ROLES_FAIL = "ADD_ROLES_FAIL"

export const UPDATE_ROLES = "UPDATE_ROLES"
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS"
export const UPDATE_ROLES_FAIL = "UPDATE_ROLES_FAIL"

export const DELETE_ROLES = "DELETE_ROLES"
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS"
export const DELETE_ROLES_FAIL = "DELETE_ROLES_FAIL"

export const ON_ACTIVATE_DEACTIVATE_ROLES = "ON_ACTIVATE_DEACTIVATE_ROLES"
export const ON_ACTIVATE_DEACTIVATE_ROLES_SUCCESS = "ON_ACTIVATE_DEACTIVATE_ROLES_SUCCESS"
export const ON_ACTIVATE_DEACTIVATE_ROLES_FAIL = "ON_ACTIVATE_DEACTIVATE_ROLES_FAIL"



