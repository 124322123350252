export const GET_VOTERS = "GET_VOTERS";
export const GET_VOTERS_SUCCESS = "GET_VOTERS_SUCCESS";
export const GET_VOTERS_FAIL = "GET_VOTERS_FAIL";

export const GET_ALL_VOTERS = "GET_ALL_VOTERS";
export const GET_ALL_VOTERS_SUCCESS = "GET_ALL_VOTERS_SUCCESS";
export const GET_ALL_VOTERS_FAIL = "GET_ALL_VOTERS_FAIL";

export const GET_DUPLICATE_VOTERS = "GET_DUPLICATE_VOTERS";
export const GET_DUPLICATE_VOTERS_SUCCESS = "GET_DUPLICATE_VOTERS_SUCCESS";
export const GET_DUPLICATE_VOTERS_FAIL = "GET_DUPLICATE_VOTERS_FAIL"

export const GET_PRINTDETAIL = "GET_PRINTDETAIL";
export const GET_PRINTDETAIL_SUCCESS = "GET_PRINTDETAIL_SUCCESS";
export const GET_PRINTDETAIL_FAIL = "GET_PRINTDETAIL_FAIL";

export const GET_VOTERS_TABLE_COLUMN_NAMES = "GET_VOTERS_TABLE_COLUMN_NAMES"
export const GET_VOTERS_TABLE_COLUMN_NAMES_SUCCESS = "GET_VOTERS_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_VOTERS_TABLE_COLUMN_NAMES_FAIL = "GET_VOTERS_TABLE_COLUMN_NAMES_FAIL"

export const GET_DUPLICATE_VOTERS_COLUMN_NAMES = "GET_DUPLICATE_VOTERS_COLUMN_NAMES";
export const GET_DUPLICATE_VOTERS_COLUMN_NAMES_SUCCESS = "GET_DUPLICATE_VOTERS_COLUMN_NAMES_SUCCESS";
export const GET_DUPLICATE_VOTERS_COLUMN_NAMES_FAIL = "GET_DUPLICATE_VOTERS_COLUMN_NAMES_FAIL";


export const ADD_VOTERS = "ADD_VOTERS"
export const ADD_VOTERS_SUCCESS = "ADD_VOTERS_SUCCESS"
export const ADD_VOTERS_FAIL = "ADD_VOTERS_FAIL"

export const UPDATE_VOTERS = "UPDATE_VOTERS"
export const UPDATE_VOTERS_SUCCESS = "UPDATE_VOTERS_SUCCESS"
export const UPDATE_VOTERS_FAIL = "UPDATE_VOTERS_FAIL"

export const DELETE_VOTERS = "DELETE_VOTERS"
export const DELETE_VOTERS_SUCCESS = "DELETE_VOTERS_SUCCESS"
export const DELETE_VOTERS_FAIL = "DELETE_VOTERS_FAIL"

export const ON_ACTIVATE_DEACTIVATE_VOTERS = "ON_ACTIVATE_DEACTIVATE_VOTERS"
export const ON_ACTIVATE_DEACTIVATE_VOTERS_SUCCESS = "ON_ACTIVATE_DEACTIVATE_VOTERS_SUCCESS"
export const ON_ACTIVATE_DEACTIVATE_VOTERS_FAIL = "ON_ACTIVATE_DEACTIVATE_VOTERS_FAIL"

export const ON_ACTIVATE_VOTERS = "ON_ACTIVATE_VOTERS"
export const ON_ACTIVATE_VOTERS_SUCCESS = "ON_ACTIVATE_VOTERS_SUCCESS"
export const ON_ACTIVATE_VOTERS_FAIL = "ON_ACTIVATE_VOTERS_FAIL"

export const GET_AREANAME = "GET_AREANAME";
export const GET_AREANAME_SUCCESS = "GET_AREANAME_SUCCESS";
export const GET_AREANAME_FAIL = "GET_AREANAME_FAIL";

export const GET_FAMILYNAME = "GET_FAMILYNAME";
export const GET_FAMILYNAME_SUCCESS = "GET_FAMILYNAME_SUCCESS";
export const GET_FAMILYNAME_FAIL = "GET_FAMILYNAME_FAIL";

export const GET_NEXT_VOTERS = "GET_NEXT_VOTERS";
export const GET_NEXT_VOTERS_SUCCESS = "GET_NEXT_VOTERS_SUCCESS";
export const GET_NEXT_VOTERS_FAIL = "GET_NEXT_VOTERS_FAIL";

export const RESET_VOTERS = "RESET_VOTERS";
