import DataTable from 'react-data-table-component'; // Import DataTable component from your library

const Table = ({ data, onRowClicked, columns }) => {
  const conditionalRowStyles = [
    {
      when: (row) => row.duplicate === true,
      style: {
        backgroundColor: '#d3d3d3', // Highlight color
        fontWeight: 'bold', // Optional: Additional styles for emphasis
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      conditionalRowStyles={conditionalRowStyles}
      onRowClicked={onRowClicked}
    />
  );
};

export default Table;