import DataTable from 'react-data-table-component';

const Table = ({ data, onRowClicked, columns }) => {

  let user = JSON.parse(sessionStorage.getItem('auth'));

  const conditionalRowStyles = [
    {
      when: (row) => {
        let alreadyRefered = false;
        row?.ReferBy?.forEach((voter) => {
          if (voter?.ReferID === user.id) {
            alreadyRefered = true;
          }
        });
        return alreadyRefered; 
      },
      style: {
        backgroundColor: '#c5bdd7', // Highlight color
        fontWeight: 'bold', // Optional: Additional styles for emphasis
      },
    }, 
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      conditionalRowStyles={conditionalRowStyles}
      onRowClicked={onRowClicked}
    />
  );
};

export default Table;
