export const GET_CLASSES = "GET_CLASSES";
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS";
export const GET_CLASSES_FAIL = "GET_CLASSES_FAIL";


export const GET_CLASSES_TABLE_COLUMN_NAMES = "GET_CLASSES_TABLE_COLUMN_NAMES"
export const GET_CLASSES_TABLE_COLUMN_NAMES_SUCCESS = "GET_CLASSES_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_CLASSES_TABLE_COLUMN_NAMES_FAIL = "GET_CLASSES_TABLE_COLUMN_NAMES_FAIL"

export const ADD_CLASSES = "ADD_CLASSES"
export const ADD_CLASSES_SUCCESS = "ADD_CLASSES_SUCCESS"
export const ADD_CLASSES_FAIL = "ADD_CLASSES_FAIL"

export const UPDATE_CLASSES = "UPDATE_CLASSES"
export const UPDATE_CLASSES_SUCCESS = "UPDATE_CLASSES_SUCCESS"
export const UPDATE_CLASSES_FAIL = "UPDATE_CLASSES_FAIL"

export const DELETE_CLASSES = "DELETE_CLASSES"
export const DELETE_CLASSES_SUCCESS = "DELETE_CLASSES_SUCCESS"
export const DELETE_CLASSES_FAIL = "DELETE_CLASSES_FAIL"

export const ON_ACTIVATE_DEACTIVATE_CLASSES = "ON_ACTIVATE_DEACTIVATE_CLASSES"
export const ON_ACTIVATE_DEACTIVATE_CLASSES_SUCCESS = "ON_ACTIVATE_DEACTIVATE_CLASSES_SUCCESS"
export const ON_ACTIVATE_DEACTIVATE_CLASSES_FAIL = "ON_ACTIVATE_DEACTIVATE_CLASSES_FAIL"



