import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Spinner, Input, Label, Button } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { BasicTable } from "../../Tables/DataTables/datatableCom";
import { columns } from "./DataTableColumns";
import SearchTextBox from "../../../Components/Common/SearchTextBox";
import CustomSearchTextBox from "../../../Components/Common/CustomSearchTextBox"
import { getBoothVoters, getBoothVotersTableColumnNames, activateBoothVoters, getClassBoothVoters, getBoothUserDetail } from "../../../store/boothVoters/actions";
import Toaster from "../../../Components/Common/Toaster";

let alphaData = [];

const BoothVoting = () => {
  const { t, i18n } = useTranslation();
  document.title = t('KW-Elections | Booth Voting');
  const user = JSON.parse(sessionStorage.getItem('auth'));
  // const [boothUser, setBoothUser] = useState(false);
  const [search, setSearch] = useState({
    voterName: "",
    VotersNo: ""
  });

  const dispatch = useDispatch();
  let module;

  user.TypeId == 1 ? module = "BOOTHVOTERS" : module = "BOOTHVOTERSCOPERATIVE"

  const { BoothVoters, isLoading, columnNames, Boothuserdetail } = useSelector((state) => {
    return {
      BoothVoters: state.BoothVoters.boothvoters,
      columnNames: state.BoothVoters.columnNames,
      isLoading: state.BoothVoters.isLoading,
      Boothuserdetail: state.BoothVoters.boothuserdetail[0]
    }
  });


  const [data, setData] = useState(BoothVoters);
  if (alphaData.length === 0) {
    alphaData = BoothVoters
  }

  useEffect(() => {
    dispatch(getBoothVoters({ UserID: user.id }))
    dispatch(getBoothUserDetail({ userID: user.id }));
    dispatch(getBoothVotersTableColumnNames({ module }))
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getClassBoothVoters({ "classNo": Boothuserdetail?.ClassNo }))
  // }, [Boothuserdetail])

  useEffect(() => {
    let filteredData = [];
    if (search.VotersNo !== "" || search.voterName !== "") {
      BoothVoters.filter((b) => {
        if (b.VotersNo == search.VotersNo) filteredData.push(b);
        if (b.voterName == search.voterName) filteredData.push(b)
      })

      setData(filteredData)
    } else {
      setData(BoothVoters)
    }
  }, [BoothVoters]);


  const handleArabicCharacter = (value) => {
    if (value === "") {
      alphaData = BoothVoters;
      setData(BoothVoters)
    } else {
      setData(BoothVoters.filter((item) => {
        return Object.values(item['Alpha']).map((entry) => entry?.toString().toLowerCase()).find((v) => v?.substring(0, value?.length) === (value?.toString().toLowerCase()));
      }))
      alphaData = BoothVoters.filter((item) => {
        return Object.values(item['Alpha']).map((entry) => entry?.toString().toLowerCase()).find((v) => v?.substring(0, value?.length) === (value?.toString().toLowerCase()));
      })
    }
  }

  const handleName = (value) => {
    if (value === "") {
      setData(BoothVoters);
    } else {
      const searchValue = value.toString().toLowerCase();
      const filteredData = BoothVoters.filter((item) => {
        const fullName = item['FullName']?.toString().toLowerCase();
        return fullName && fullName.includes(searchValue);
      });
      setData(filteredData);
    }
  };

  function onActiveChange(voters, e) {
    console.log('voters: ', voters);
    const votersObj = {}
    const votedMarkedBY = {}
    let votedDateTime = new Date().toLocaleString();
    votedDateTime = votedDateTime.replaceAll('/', '-')
    votedDateTime = votedDateTime.replaceAll(',', '')

    votedMarkedBY['BoothCoordinatorID'] = user.id
    votedMarkedBY['RoleID'] = user.RoleID.toString();
    votedMarkedBY['Status'] = true
    votedMarkedBY['Date'] = votedDateTime;


    // votersObj['_id'] = voters._id;
    votersObj['Alpha'] = voters.Alpha;
    votersObj['FullName'] = voters.FullName;
    votersObj['VotersNo'] = voters.VotersNo;
    // votersObj['VotersStatus'] = true;
    //  votersObj['VotedDateTime'] = votedDateTime;
    //  votersObj['VotedMarkedBy'] = votedMarkedBY
    // dispatch(activateBoothVoters(votersObj))

   
      votersObj['_id'] = voters._id;
      votersObj['VotersStatus'] = voters.VotersStatus;
      // votersObj['VotersStatus'] = true;
      votersObj['VotedDateTime'] = votedDateTime;
      votersObj['VotedMarkedBy'] = votedMarkedBY
      dispatch(activateBoothVoters(votersObj))

  }

  const handleClear = () => {
    if (user.TypeId == 1) document.getElementById('alpha').value = ''
    document.getElementById('voterId').value = ''
    document.getElementById('voterName').value = ''
    setData(BoothVoters);
  }

  useEffect(() => {
    console.log('search: ', search);
  }, [search])



  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <Container fluid>
          <Row className='mb-3'>
            <Col>
              <BreadCrumb title={t('Booth Voting')} />
            </Col>
          </Row>
          {(isLoading === false) ? <div>
            <Row className='mb-3'>
              <Col>
                <BreadCrumb title={`Area: ${Boothuserdetail?.AreaName ? Boothuserdetail?.AreaName : '-'} / School: ${Boothuserdetail?.SchoolName ? Boothuserdetail?.SchoolName : '-'} / Class Name: ${Boothuserdetail?.ClassName}`} />
              </Col>
            </Row>
          </div> : ""}
          <div className="card p-4 border">
            <Row className='mb-3'>

              {
                user.TypeId == 1 ?
                  <Col className="col-md-3 col-6 mb-4">
                    <Label>{t('Voter Alphabet')}</Label>
                    <Input
                      type="text"
                      className={i18n.language === 'ar' ? 'form-control float-start mw-400' : 'form-control float-end mw-400'}
                      placeholder={t('Search') + '...'}
                      id="alpha"
                      onChange={(e) => handleArabicCharacter(e.target.value)}
                    />
                  </Col>
                  :
                  null
              }

              <Col className="col-md-3 col-6 mb-4">
                <Label>{t('Voter Number')}</Label>
                <CustomSearchTextBox initialData={alphaData} filter="VotersNo" setData={setData} id="voterId" setSearch={setSearch} name="VotersNo" />
              </Col>

              <Col className="col-md-3 col-6 mb-4">
                <Label>{t('Voter Name')}</Label>
                {/* <CustomSearchTextBox initialData={BoothVoters} filter="FullName" setData={setData} id="voterName" setSearch={setSearch} name="voterName" /> */}
                {/* <SearchTextBox initialData={BoothVoters} filter="FullName" setData={setData} id="voterName" /> */}
                <Input
                  type="text"
                  className={i18n.language === 'ar' ? 'form-control float-start mw-400' : 'form-control float-end mw-400'}
                  placeholder={t('Search') + '...'}
                  id="alpha"
                  onChange={(e) => handleName(e.target.value)}
                />
              </Col>

              <Col className="col-md-3 col-6 mb-4">
                <Label>&nbsp; </Label> <br />
                <Button onClick={handleClear} className="cis-width-120">{t('Clear')} <i className="ri-filter-off-line"></i></Button>
              </Col>

            </Row>
          </div>
          <Row>
            <Col>
              {isLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner style={{
                  height: '3rem',
                  width: '3rem',
                }} className='me-2'> Loading... </Spinner>
              </div> :
                <BasicTable
                  data={data}
                  columns={columns(columnNames, i18n, t, onActiveChange)}
                />
              }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BoothVoting;