import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { getLocalization, getLocalizationTableColumnNames, getScreens, } from "../../../store/localization/actions";
import { BasicTable } from "../../Tables/DataTables/datatableCom";

const Test2 = () => {
    const { t, i18n } = useTranslation();
    document.title = t('KW-Elections | Localization');
    const authUser = JSON.parse(sessionStorage.getItem("authUser"));
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [finalData, setFinalData] = useState([]);


    let keys = [];
    let values = [];

    const getKeys = (lc) => {

        if (lc.length) {
            let temp = lc[0];
            let k = Object.keys(temp);
            let v = Object.values(temp);

            for (let i = 0; i < k.length; i++) {
                let tempKey = k[i];
                let tempValue = v[i];

                keys.push({ labelKey: tempKey, labelValue: tempValue });
                values.push(tempKey)
            }

        }
    }


    const { Localization, isLoading, columnNames } = useSelector((state) => ({
        Localization: state.Localization.localization,
        columnNames: state.Localization.columnNames,
        isLoading: state.Localization.isLoading,
    }));

    useEffect(() => {
        dispatch(getScreens());
        dispatch(getLocalization());
        dispatch(getLocalizationTableColumnNames());
    }, [dispatch]);

    useEffect(() => {
        setData(Localization);
        getKeys(Localization)
        setFinalData(keys);
    }, [Localization]);


    const columns = [
        {
            name: 'Label Key',
            selector: (row) => row.labelKey,
        },
        {
            name: 'Label Value',
            selector: (row) => row.labelValue,

        },
    ];

    return (
        <div>
            {
                isLoading ? <div>Loading</div> : <BasicTable columns={columns} data={finalData} />
            }

        </div>

    );
};

export default Test2;
