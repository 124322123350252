export const GET_REFERVOTERS = "GET_REFERVOTERS";
export const GET_REFERVOTERS_SUCCESS = "GET_REFERVOTERS_SUCCESS";
export const GET_REFERVOTERS_FAIL = "GET_REFERVOTERS_FAIL";


export const GET_REFERVOTERS_TABLE_COLUMN_NAMES = "GET_REFERVOTERS_TABLE_COLUMN_NAMES"
export const GET_REFERVOTERS_TABLE_COLUMN_NAMES_SUCCESS = "GET_REFERVOTERS_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_REFERVOTERS_TABLE_COLUMN_NAMES_FAIL = "GET_REFERVOTERS_TABLE_COLUMN_NAMES_FAIL"

export const ADD_REFERVOTERS = "ADD_REFERVOTERS"
export const ADD_REFERVOTERS_SUCCESS = "ADD_REFERVOTERS_SUCCESS"
export const ADD_REFERVOTERS_FAIL = "ADD_REFERVOTERS_FAIL"

export const UPDATE_REFERVOTERS = "UPDATE_REFERVOTERS"
export const UPDATE_REFERVOTERS_SUCCESS = "UPDATE_REFERVOTERS_SUCCESS"
export const UPDATE_REFERVOTERS_FAIL = "UPDATE_REFERVOTERS_FAIL"

export const DELETE_REFERVOTERS = "DELETE_REFERVOTERS"
export const DELETE_REFERVOTERS_SUCCESS = "DELETE_REFERVOTERS_SUCCESS"
export const DELETE_REFERVOTERS_FAIL = "DELETE_REFERVOTERS_FAIL"

export const ON_ACTIVATE_DEACTIVATE_REFERVOTERS = "ON_ACTIVATE_DEACTIVATE_REFERVOTERS"
export const ON_ACTIVATE_DEACTIVATE_REFERVOTERS_SUCCESS = "ON_ACTIVATE_DEACTIVATE_REFERVOTERS_SUCCESS"
export const ON_ACTIVATE_DEACTIVATE_REFERVOTERS_FAIL = "ON_ACTIVATE_DEACTIVATE_REFERVOTERS_FAIL"