export const GET_ELECTIONDAY_REPORT = "GET_ELECTIONDAY_REPORT";
export const GET_ELECTIONDAY_REPORT_SUCCESS = "GET_ELECTIONDAY_REPORT_SUCCESS";
export const GET_ELECTIONDAY_REPORT_FAIL = "GET_ELECTIONDAY_REPORT_FAIL";

export const GET_ELECTIONDAY_REPORT_COLUMN_NAMES =
  "GET_ELECTIONDAY_REPORT_COLUMN_NAMES";
export const GET_ELECTIONDAY_REPORT_COLUMN_NAMES_SUCCESS =
  "GET_ELECTIONDAY_REPORT_COLUMN_NAMES_SUCCESS";
export const GET_ELECTIONDAY_REPORT_COLUMN_NAMES_FAIL =
  "GET_ELECTIONDAY_REPORT_COLUMN_NAMES_FAIL";
