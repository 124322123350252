// import React from "react";
// import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
//import Widget from "./Widgets";
// import BestSellingProducts from "./BestSellingProducts";
// import RecentActivity from "./RecentActivity";
// import RecentOrders from "./RecentOrders";
// import Revenue from "./Revenue";
// import SalesByLocations from "./SalesByLocations";
// import Section from "./Section";
// import StoreVisits from "./StoreVisits";
// import TopSellers from "./TopSellers";
// import { useTranslation } from "react-i18next";
// import SecondWidget from "./SecondWidget";
// import { Markers, Negative } from "../Charts/ApexCharts/BarCharts/BarCharts";
// import AgeGroupChart from "./AgeGroupChart";

// const DashboardEcommerce = () => {
//   const { i18n, t } = useTranslation();
//   document.title = t('Dashboard | KW-Elections Admin & Dashboard');
//   return (
//     <React.Fragment>
//       <div className="page-content">
//         <Container fluid>
//           <Row>
//             <Col>
//               <div className="h-100">
//                 <Section />
//                 <Row>
//                   <Widget />
//                 </Row>
//                 <Row>
//                   <Col xl={8}>
//                     <Revenue />
//                   </Col>
//                   {/* <StoreVisits /> */}
//                   <AgeGroupChart/>
//                   {/* <SalesByLocations /> */}
//                 </Row>
//                 <Row>
//                   <SecondWidget />
//                 </Row>
//                 <Row>
//                   {/* <BestSellingProducts /> */}
//                   {/* <TopSellers /> */}
//                 </Row>
//                 <Row>
//                   {/* <StoreVisits /> */}
//                   {/* <RecentOrders /> */}
//                 </Row>
//               </div>
//             </Col>
//             {/* <RecentActivity /> */}
//           </Row>
//         </Container>
//       </div>
//     </React.Fragment>
//   );
// };

// export default DashboardEcommerce;


import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Widget from "./Widgets";
import Revenue from "./Revenue";
import Section from "./Section";
import { useTranslation } from "react-i18next";
import SecondWidget from "./SecondWidget";
import AgeGroupChart from "./AgeGroupChart";
import { useDispatch, useSelector } from 'react-redux';
import { getTotalCount } from '../../store/dashboard/actions';
import CountUp from "react-countup";
import NewDashboard from './NewDashboard';

const DashboardEcommerce = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const auth = JSON.parse(sessionStorage.getItem('auth'));


  document.title = t('Dashboard | KW-Elections Admin & Dashboard');
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section />

                <NewDashboard />

                <Row hidden>
              {/* {<Widget />} */}
                </Row>
                <Row hidden>
                  <Col xl={8}>
                    <h2>Revenue</h2>
                    {/* {<Revenue />} */}
                  </Col>
                  {/* <StoreVisits /> */}
                  <h2>AgeGroupChart</h2>
                 {/* { <AgeGroupChart />} */}
                  {/* <SalesByLocations /> */}
                </Row>
                <Row hidden>
                 {/* { <SecondWidget />} */}
                </Row>
                <Row>
                  {/* <BestSellingProducts /> */}
                  {/* <TopSellers /> */}
                </Row>
                <Row>
                  {/* <StoreVisits /> */}
                  {/* <RecentOrders /> */}
                </Row>
              </div>
            </Col>
            {/* <RecentActivity /> */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;