import React, { useEffect, useState } from 'react';
import { Col, Row, Label, Input, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { getTotalCount, getCount } from '../../store/dashboard/actions';
import CounterCard from './CounterCard';
import { useNavigate } from 'react-router-dom';
import { getAreaName } from "../../store/voters/actions";
import totalVotersIcon from './../../assets/images/fluent_people-team-28-regular.png';
import totalVotedIcon from "../../assets/images/totalVoted.png";
import totalToBeVotedIcon from "../../assets/images/totalToBeVoted.png";
import totalReferencesIcon from "../../assets/images/totalReferences.png";
import totalSubAdminIcon from "../../assets/images/totalSubAdmins.png";
import totalAreasIcon from "../../assets/images/totalAreas.png";
import totalSchoolsIcons from "../../assets/images/totalSchools.png";
import totalClassesIcon from "../../assets/images/totalClasses.png";


const NewDashboard = () => {

    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const auth = JSON.parse(sessionStorage.getItem('auth'));
    const [totalVoters, setTotalVoters] = useState(0);
    const [totalVoted, setTotalVoted] = useState(0);
    const [totalToBeVoted, setTotalToBeVoted] = useState(0);
    const [totalRefered, setTotalRefered] = useState(0);
    const [totalReferedVoted, setTotalReferedVoted] = useState(0);
    const [totalReferedToBeVoted, setTotalReferedToBeVoted] = useState(0);
    const [totalSubAdmin, setTotalSubAdmin] = useState(0);
    const [totalReference, setTotalReference] = useState(0);
    const [totalSubReference, setTotalSubReference] = useState(0);
    const [totalAreas, setTotalAreas] = useState(0);
    const [totalSchools, setTotalSchools] = useState(0)
    const [totalClasses, setTotalClasses] = useState(0);
    const [myRefered, setMyRefered] = useState(0);
    const [myReferedVoted, setMyreferedVoted] = useState(0);
    const [myReferedToBeVoted, setMyReferedtoBeVoted] = useState(0);
    const [areaNameList, setAreaNameList] = useState([]);
    const [totalDuplicateVoter, setTotalDuplicateVoter] = useState(0);
    const [totalDuplicateVoted, setTotalDuplicateVoted] = useState(0);
    const [totalDuplicateToBeVoted, setTotalDuplicateToBeVoted] = useState(0);
    const [ElectionDetail, setElectionDetail] = useState();

    const navigate = useNavigate();

    const roleId = auth.RoleID;
    let user = sessionStorage.getItem('auth')
    user = JSON.parse(user);

    const roles = {
        [1]: "Admin",
        [2]: "Candidate",
        [3]: "Sub Admin",
        [4]: "Reference",
        [5]: "Sub Reference",
        [6]: "Booth Cordinator",
        [7]: "BOOTH_ADMIN",
        [8]: "CoOperative Candidate",
        [9]: "CoOperative Sub-Refernce",
        [10]: "CoOperative Booth-Cordinator"
    }

    const {
        votersCount: Voters,
        votedCount: Voted,
        toBeVotedCount: ToBeVoted,
        referedCount: Refered,
        referedVotedCount: ReferedVoted,
        referedToBeVotedCount: ReferedToBeVoted,
        totalSubAdmins: SubAdmin,
        totalReferences: References,
        totalSubReferences: SubReferences,
        totalAreas: Areas,
        totalSchools: Schools,
        totalClasses: Classes,
        myTotalRefered: mRefered,
        myReferedVoted: mReferedVoted,
        myReferToBeVoted: mReferedToBeVoted,
        totalDuplicateVoter: TDVoter,
        totalDuplicateVoted: TDVoted,
        totalDuplicateToBeVoted: TDTBVoted
    } = useSelector(state => state.Dashboard);

    const { areaName } = useSelector((state) => ({ areaName: state.Voters.areaName }));

    const [searchQuery, setSearchQuery] = useState({
        FamilyName: "",
        AreaID: "",
        SexCode: "",
    })

    const handleChange = (e) => {
        setSearchQuery((preValue) => ({
            ...preValue,
            [e.target.name]: e.target.value,
        }));
    }

    const fetchTotalCount = () => {
        dispatch(getTotalCount({ UserID: auth.id, searchQuery: searchQuery }));
        dispatch(getCount({ UserID: auth.id, searchQuery: searchQuery }));
    }

    const handleSearch = () => {
        dispatch(getTotalCount({ UserID: auth.id, searchQuery: searchQuery }));
        dispatch(getCount({ UserID: auth.id, searchQuery: searchQuery }));
    }

    const handleClear = () => {
        document.getElementById('familyName').value = ''
        document.getElementById('areaName').value = ''
        document.getElementById('gender').value = ''
        setSearchQuery({
            FamilyName: "",
            AreaID: "",
            SexCode: "",
        })
        fetchTotalCount();
    }

    useEffect(() => {
        const interval = setInterval(
            () => {
                const query = {
                    FamilyName: "",
                    AreaID: "",
                    SexCode: "",
                }

                dispatch(getTotalCount({ UserID: auth.id, searchQuery: searchQuery }));
                dispatch(getCount({ UserID: auth.id, searchQuery: searchQuery }));
            }, 60000);
        return () => { clearInterval(interval) };
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAreaName({ userID: user.id }))
        dispatch(getTotalCount({ UserID: auth.id, searchQuery: searchQuery }));
        dispatch(getCount({ UserID: auth.id, searchQuery: searchQuery }));
        setElectionDetail(user.TypeId);
    }, [])

    useEffect(() => {
        setTotalVoters(Voters);
        setTotalVoted(Voted);
        setTotalToBeVoted(ToBeVoted);
        setTotalRefered(Refered);
        setTotalReferedVoted(ReferedVoted);
        setTotalReferedToBeVoted(ReferedToBeVoted)
        setTotalSubAdmin(SubAdmin);
        setTotalReference(References);
        setTotalSubReference(SubReferences);
        setTotalAreas(Areas);
        setTotalSchools(Schools);
        setTotalClasses(Classes);
        setMyRefered(mRefered);
        setMyreferedVoted(mReferedVoted);
        setMyReferedtoBeVoted(mReferedToBeVoted)
        setAreaNameList(areaName);
        setTotalDuplicateVoter(TDVoter);
        setTotalDuplicateVoted(TDVoted);
        setTotalDuplicateToBeVoted(TDTBVoted);
    }, [Voters, Voted, ToBeVoted, Refered, ReferedVoted, ReferedToBeVoted, SubAdmin, References, SubReferences, Areas, Schools, Classes, areaName])


    return (
        <React.Fragment>

            {
                roleId <= 4 || roleId == 8 ?
                    (
                        <Row className='mb-3 all-voter-list-row'>

                            <Col>
                                <Label>{t('Family Name')}</Label>
                                <Input type="text" className={i18n.language === 'ar' ? 'form-control float-start' : 'form-control float-end'} placeholder={t('Search') + '...'}
                                    id="familyName" name="FamilyName"
                                    onChange={(e) => handleChange(e)}
                                />
                            </Col>

                            <Col>
                                <Label>{t('Area Name')}</Label>
                                <Input type="select" name="AreaID" className="form-select" id="areaName" onChange={(e) => handleChange(e)} >
                                    <option value='' default>{t('Select')} </option>
                                    {
                                        <React.Fragment>
                                            {areaNameList?.map((item, key) => (<option value={item.ID} key={item._id}>{item.AreaName}</option>))}
                                        </React.Fragment>
                                    }
                                </Input>
                            </Col>

                            <Col>
                                <Label>{t('Gender')}</Label>
                                <Input type="select" name="SexCode" className="form-select" id="gender" onChange={(e) => handleChange(e)} >
                                    <option value='' default>{t('Select')}</option>
                                    <option value='1' default>{t('Male')}</option>
                                    <option value='2' default>{t('Female')}</option>
                                </Input>
                            </Col>

                            <Col className="cis-btn-col">
                                <label> &nbsp;</label>
                                <div className="refer-voters-btn-wrap">
                                    <Button onClick={handleSearch} className="cis-width-120">{t('Search')} <i className="ri-search-2-line"></i></Button>
                                    <Button onClick={handleClear} className="cis-width-120">{t('Clear')} <i className="ri-filter-off-line"></i></Button>

                                </div>
                            </Col>
                        </Row>
                    )
                    :
                    (null)

            }


            <Row>

                {/* Total Voters Block */}
                <Col xl={6}>
                    <div className="cis-card_wrap colored_shade_1">
                        <Row>
                            <Col xl={12} md={12}>
                                <h3>{t('Voters')}</h3>
                            </Col>

                            <Col xl={4} md={4}
                                onClick={() => {
                                    roleId < 4
                                        ?
                                        navigate("/data-reports/all-voters-list")
                                        :
                                        navigate("/dashboard")
                                }}
                            >
                                <CounterCard
                                    t={t}
                                    title={'Total Voters'}
                                    count={totalVoters}
                                    icon={totalVotersIcon}
                                />
                            </Col>

                            <Col xl={4} md={4}
                                onClick={() => {
                                    roleId < 4
                                        ?
                                        navigate("/data-reports/all-voters-list", { state: { status: true } })
                                        :
                                        navigate("/dashboard")
                                }}>
                                <CounterCard
                                    t={t}
                                    title={'Total Voted'}
                                    count={totalVoted}
                                    icon={totalVotedIcon}
                                />
                            </Col>

                            <Col xl={4} md={4}
                                onClick={() => {
                                    roleId < 4
                                        ?
                                        navigate("/data-reports/all-voters-list", { state: { status: false } })
                                        :
                                        navigate("/dashboard")
                                }}>
                                <CounterCard
                                    t={t}
                                    title={'Total To Be Voted'}
                                    count={totalToBeVoted}
                                    icon={totalToBeVotedIcon}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>

                {/* Referred Voters Block */}
                {
                    roleId <= 4 || roleId == 8 ?
                        (
                            <Col xl={6}>
                                <div className="cis-card_wrap colored_shade_2">
                                    <Row>
                                        <Col xl={12} md={12} >
                                            <h3>{t('Referred Voters')}</h3>
                                        </Col>

                                        <Col xl={4} md={4} onClick={() => { navigate("/data-reports/all-refered-voters-list", { state: { status: "all" } }) }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Referred Voters'}
                                                count={totalRefered}
                                                icon={totalVotersIcon}
                                            />
                                        </Col>

                                        <Col xl={4} md={4} onClick={() => { navigate("/data-reports/all-refered-voters-list", { state: { status: true } }) }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Referred Voted'}
                                                count={totalReferedVoted}
                                                icon={totalVotedIcon}
                                            />
                                        </Col>

                                        <Col xl={4} md={4} onClick={() => { navigate("/data-reports/all-refered-voters-list", { state: { status: false } }) }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Referred To Be Voted'}
                                                count={totalReferedToBeVoted}
                                                icon={totalToBeVotedIcon}
                                            />
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        )
                        :
                        (null)
                }

                {/* My Refered Voters Block */}

                {
                    roleId == 5 || roleId == 9 ?
                        (
                            <Col xl={6} >
                                <div className="cis-card_wrap colored_shade_3">
                                    <Row>
                                        <Col xl={12} md={12}>
                                            <h3>{t(`${roles[roleId]} Refered Voters`)}</h3>
                                        </Col>

                                        <Col xl={4} md={4} onClick={() => { navigate("/operations/my-refered-voters", { state: { status: "all" } }) }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Referred Voters'}
                                                count={myRefered}
                                                icon={totalVotersIcon}
                                            />
                                        </Col>

                                        <Col xl={4} md={4} onClick={() => { navigate("/operations/my-refered-voters", { state: { status: true } }) }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Referred Voted'}
                                                count={myReferedVoted}
                                                icon={totalVotedIcon}
                                            />
                                        </Col>

                                        <Col xl={4} md={4} onClick={() => { navigate("/operations/my-refered-voters", { state: { status: false } }) }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Referred To Be Voted'}
                                                count={myReferedToBeVoted}
                                                icon={totalToBeVotedIcon}
                                            />
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        )
                        :
                        (null)
                }


                {/* My Team Block */}
                {
                    roleId <= 4 || roleId == 8 ?
                        (
                            <Col xl={6}>
                                <div className="cis-card_wrap colored_shade_3 card_strip">
                                    <Row>

                                        <Col xl={12} md={12} hidden>
                                            <h3>{t('My Team')}</h3>
                                        </Col>

                                        {
                                            ElectionDetail == 1 ?
                                                <Col
                                                    xl={12}
                                                    md={12}
                                                    onClick={() => { navigate("/analytical-reports/role-wise-report", { state: { status: 3 } }) }}
                                                >
                                                    <CounterCard
                                                        t={t}
                                                        title={'Total Sub Admins'}
                                                        count={totalSubAdmin}
                                                        icon={totalSubAdminIcon}
                                                    />
                                                </Col>
                                                :
                                                null
                                        }


                                        {
                                            ElectionDetail == 1 ?
                                                <Col
                                                    xl={12}
                                                    md={12}
                                                    onClick={() => { navigate("/analytical-reports/role-wise-report", { state: { status: 4 } }) }}
                                                >
                                                    <CounterCard
                                                        t={t}
                                                        title={'Total References'}
                                                        count={totalReference}
                                                        icon={totalReferencesIcon}
                                                    />
                                                </Col>
                                                :
                                                null
                                        }


                                        <Col xl={12} md={12} onClick={() => { navigate("/analytical-reports/role-wise-report", { state: { status: 5 } }) }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Sub References'}
                                                count={totalSubReference}
                                                icon={totalReferencesIcon}
                                            />
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        )
                        :
                        (null)
                }



                {/* Analytics Block */}
                {
                    roleId <= 3 || roleId == 8 ?
                        (
                            <Col xl={6}>
                                <div className="cis-card_wrap colored_shade_4 card_strip">
                                    <Row>

                                        <Col xl={12} md={12} hidden>
                                            <h3>{t('Analytics')}</h3> 
                                        </Col>
                                        <Col xl={12} md={12} onClick={() => { navigate("/analytical-reports/area-wise-report") }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Areas'}
                                                count={totalAreas}
                                                icon={totalAreasIcon}
                                            />
                                        </Col>
                                        <Col xl={12} md={12} onClick={() => { navigate("/analytical-reports/school-wise-report") }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Schools'}
                                                count={totalSchools}
                                                icon={totalSchoolsIcons}
                                            />
                                        </Col>
                                        <Col xl={12} md={12} onClick={() => { navigate("/analytical-reports/booth-wise-reports") }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Classes'}
                                                count={totalClasses}
                                                icon={totalClassesIcon}
                                            />
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        )
                        :
                        (null)
                }

                {/* Duplicate Voter block */}
                {
                    roleId <= 3 || roleId == 8 ?
                        (
                            <Col xl={3} md={6} lg={4}>
                                <div className="cis-card_wrap colored_shade_2 d-inline-block w-auto">
                                    <Row>
                                        <Col xl={12} md={12} >
                                            <h3>{t('Duplicate Voters')}</h3>
                                        </Col>

                                        <Col xl={12} md={12} onClick={() => { navigate("/data-reports/duplicate-voters-list", { state: { status: "all" } }) }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Duplicate Voters'}
                                                count={totalDuplicateVoter}
                                                icon={totalVotersIcon}
                                            />
                                        </Col>

                                        {/* <Col xl={4} md={4} onClick={() => { navigate("/data-reports/duplicate-voters-list", { state: { status: true } }) }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Duplicate Voted'}
                                                count={totalDuplicateVoted}
                                                icon={totalVotedIcon}
                                            />
                                        </Col>

                                        <Col xl={4} md={4} onClick={() => { navigate("/data-reports/duplicate-voters-list", { state: { status: false } }) }}>
                                            <CounterCard
                                                t={t}
                                                title={'Total Duplicate To Be Voted'}
                                                count={totalDuplicateToBeVoted}
                                                icon={totalToBeVotedIcon}
                                            />
                                        </Col> */}

                                    </Row>
                                </div>
                            </Col>
                        )
                        :
                        (null)
                }

            </Row>
        </React.Fragment>)
}
export default NewDashboard;