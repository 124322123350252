import React from 'react';
import CountUp from 'react-countup'; 
import icon_ from './../../assets/images/fluent_people-team-28-regular.png'; 



const CounterCard = ({t, title, count , icon}) => (
    <div className="card-animate card"> 
        <div className="card-body">
            <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                        {t(title)}
                    </p>
                </div> 
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                    <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                        <span className="counter-value" data-target="559.25">
                            <CountUp start={0} end={count} duration={1} />
                        </span>
                    </h4>
                </div>
            </div>
            <div className='card-icon'>
                <img src={icon}/>
            </div>
        </div>
    </div>
);

export default CounterCard;