export const GET_VOTERSMANAGEMENT = "GET_VOTERSMANAGEMENT";
export const GET_VOTERSMANAGEMENT_SUCCESS = "GET_VOTERSMANAGEMENT_SUCCESS";
export const GET_VOTERSMANAGEMENT_FAIL = "GET_VOTERSMANAGEMENT_FAIL";


export const GET_VOTERSMANAGEMENT_TABLE_COLUMN_NAMES = "GET_VOTERSMANAGEMENT_TABLE_COLUMN_NAMES"
export const GET_VOTERSMANAGEMENT_TABLE_COLUMN_NAMES_SUCCESS = "GET_VOTERSMANAGEMENT_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_VOTERSMANAGEMENT_TABLE_COLUMN_NAMES_FAIL = "GET_VOTERSMANAGEMENT_TABLE_COLUMN_NAMES_FAIL"

export const ADD_VOTERSMANAGEMENT = "ADD_VOTERSMANAGEMENT"
export const ADD_VOTERSMANAGEMENT_SUCCESS = "ADD_VOTERSMANAGEMENT_SUCCESS"
export const ADD_VOTERSMANAGEMENT_FAIL = "ADD_VOTERSMANAGEMENT_FAIL"

export const UPDATE_VOTERSMANAGEMENT = "UPDATE_VOTERSMANAGEMENT"
export const UPDATE_VOTERSMANAGEMENT_SUCCESS = "UPDATE_VOTERSMANAGEMENT_SUCCESS"
export const UPDATE_VOTERSMANAGEMENT_FAIL = "UPDATE_VOTERSMANAGEMENT_FAIL"

export const DELETE_VOTERSMANAGEMENT = "DELETE_VOTERSMANAGEMENT"
export const DELETE_VOTERSMANAGEMENT_SUCCESS = "DELETE_VOTERSMANAGEMENT_SUCCESS"
export const DELETE_VOTERSMANAGEMENT_FAIL = "DELETE_VOTERSMANAGEMENT_FAIL"

export const ON_ACTIVATE_DEACTIVATE_VOTERSMANAGEMENT = "ON_ACTIVATE_DEACTIVATE_VOTERSMANAGEMENT"
export const ON_ACTIVATE_DEACTIVATE_VOTERSMANAGEMENT_SUCCESS = "ON_ACTIVATE_DEACTIVATE_VOTERSMANAGEMENT_SUCCESS"
export const ON_ACTIVATE_DEACTIVATE_VOTERSMANAGEMENT_FAIL = "ON_ACTIVATE_DEACTIVATE_VOTERSMANAGEMENT_FAIL"

export const RESET_VOTERSMANAGEMENT = "RESET_VOTERSMANAGEMENT";


