export const GET_TOTAL_COUNT = "GET_TOTAL_COUNT";
export const GET_TOTAL_COUNT_SUCCESS = "GET_TOTAL_COUNT_SUCCESS"
export const GET_TOTAL_COUNT_FAIL = "GET_TOTAL_COUNT_FAIL"

export const GET_COUNT = "GET_COUNT"
export const GET_COUNT_SUCCESS = "GET_COUNT_SUCCESS"
export const GET_COUNT_FAIL = "GET_COUNT_FAIL"

export const GET_AGE_COUNT = "GET_AGE_COUNT"
export const GET_AGE_COUNT_SUCCESS = "GET_AGE_COUNT_SUCCESS"
export const GET_AGE_COUNT_FAIL = "GET_AGE_COUNT_FAIL"

export const GET_VOTERS_STATISTICS = "GET_VOTERS_STATISTICS"
export const GET_VOTERS_STATISTICS_SUCCESS = "GET_VOTERS_STATISTICS_SUCCESS"
export const GET_VOTERS_STATISTICS_FAIL = "GET_VOTERS_STATISTICS_FAIL"







