export const GET_ELECTIONS = "GET_ELECTIONS";
export const GET_ELECTIONS_SUCCESS = "GET_ELECTIONS_SUCCESS";
export const GET_ELECTIONS_FAIL = "GET_ELECTIONS_FAIL";

export const GET_ELECTIONS_TABLE_COLUMN_NAMES = "GET_ELECTIONS_TABLE_COLUMN_NAMES"
export const GET_ELECTIONS_TABLE_COLUMN_NAMES_SUCCESS = "GET_ELECTIONS_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_ELECTIONS_TABLE_COLUMN_NAMES_FAIL = "GET_ELECTIONS_TABLE_COLUMN_NAMES_FAIL"

export const ADD_ELECTIONS = "ADD_ELECTIONS"
export const ADD_ELECTIONS_SUCCESS = "ADD_ELECTIONS_SUCCESS"
export const ADD_ELECTIONS_FAIL = "ADD_ELECTIONS_FAIL"

export const UPDATE_ELECTIONS = "UPDATE_ELECTIONS"
export const UPDATE_ELECTIONS_SUCCESS = "UPDATE_ELECTIONS_SUCCESS"
export const UPDATE_ELECTIONS_FAIL = "UPDATE_ELECTIONS_FAIL"

export const DELETE_ELECTIONS = "DELETE_ELECTIONS"
export const DELETE_ELECTIONS_SUCCESS = "DELETE_ELECTIONS_SUCCESS"
export const DELETE_ELECTIONS_FAIL = "DELETE_ELECTIONS_FAIL"

export const ON_ACTIVATE_DEACTIVATE_ELECTIONS = "ON_ACTIVATE_DEACTIVATE_ELECTIONS"
export const ON_ACTIVATE_DEACTIVATE_ELECTIONS_SUCCESS = "ON_ACTIVATE_DEACTIVATE_ELECTIONS_SUCCESS"
export const ON_ACTIVATE_DEACTIVATE_ELECTIONS_FAIL = "ON_ACTIVATE_DEACTIVATE_ELECTIONS_FAIL"

export const GET_ELECTION_DETAILS = "GET_ELECTION_DETAILS";
export const GET_ELECTION_DETAILS_SUCCESS = "GET_ELECTION_DETAILS_SUCCESS";
export const GET_ELECTION_DETAILS_FAIL = "GET_ELECTION_DETAILS_FAIL";

export const GET_ELECTION_TYPE = "GET_ELECTION_TYPE";
export const GET_ELECTION_TYPE_SUCCESS = "GET_ELECTION_TYPE_SUCCESS";
export const GET_ELECTION_TYPE_FAIL = "GET_ELECTION_TYPE_FAIL"