export const GET_CIRCLES = "GET_CIRCLES";
export const GET_CIRCLES_SUCCESS = "GET_CIRCLES_SUCCESS";
export const GET_CIRCLES_FAIL = "GET_CIRCLES_FAIL";

export const GET_CIRCLES_BY_ELECTIONID = "GET_CIRCLES_BY_ELECTIONID";
export const GET_CIRCLES_BY_ELECTIONID_SUCCESS = "GET_CIRCLES_BY_ELECTIONID_SUCCESS";
export const GET_CIRCLES_BY_ELECTIONID_FAIL = "GET_CIRCLES_BY_ELECTIONID_FAIL";

export const GET_CIRCLES_TABLE_COLUMN_NAMES = "GET_CIRCLES_TABLE_COLUMN_NAMES"
export const GET_CIRCLES_TABLE_COLUMN_NAMES_SUCCESS = "GET_CIRCLES_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_CIRCLES_TABLE_COLUMN_NAMES_FAIL = "GET_CIRCLES_TABLE_COLUMN_NAMES_FAIL"

export const ADD_CIRCLES = "ADD_CIRCLES"
export const ADD_CIRCLES_SUCCESS = "ADD_CIRCLES_SUCCESS"
export const ADD_CIRCLES_FAIL = "ADD_CIRCLES_FAIL"

export const UPDATE_CIRCLES = "UPDATE_CIRCLES"
export const UPDATE_CIRCLES_SUCCESS = "UPDATE_CIRCLES_SUCCESS"
export const UPDATE_CIRCLES_FAIL = "UPDATE_CIRCLES_FAIL"

export const DELETE_CIRCLES = "DELETE_CIRCLES"
export const DELETE_CIRCLES_SUCCESS = "DELETE_CIRCLES_SUCCESS"
export const DELETE_CIRCLES_FAIL = "DELETE_CIRCLES_FAIL"

export const ON_ACTIVATE_DEACTIVATE_CIRCLES = "ON_ACTIVATE_DEACTIVATE_CIRCLES"
export const ON_ACTIVATE_DEACTIVATE_CIRCLES_SUCCESS = "ON_ACTIVATE_DEACTIVATE_CIRCLES_SUCCESS"
export const ON_ACTIVATE_DEACTIVATE_CIRCLES_FAIL = "ON_ACTIVATE_DEACTIVATE_CIRCLES_FAIL"



