export const GET_MY_REFERED_VOTERS = "GET_MY_REFERED_VOTERS";
export const GET_MY_REFERED_VOTERS_SUCCESS = "GET_MY_REFERED_VOTERS_SUCCESS";
export const GET_MY_REFERED_VOTERS_FAIL = "GET_MY_REFERED_VOTERS_FAIL";


export const GET_MY_REFERED_VOTERS_TABLE_COLUMN_NAMES = "GET_MY_REFERED_VOTERS_TABLE_COLUMN_NAMES"
export const GET_MY_REFERED_VOTERS_TABLE_COLUMN_NAMES_SUCCESS = "GET_MY_REFERED_VOTERS_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_MY_REFERED_VOTERS_TABLE_COLUMN_NAMES_FAIL = "GET_MY_REFERED_VOTERS_TABLE_COLUMN_NAMES_FAIL"

export const ADD_MY_REFERED_VOTERS = "ADD_MY_REFERED_VOTERS"
export const ADD_MY_REFERED_VOTERS_SUCCESS = "ADD_MY_REFERED_VOTERS_SUCCESS"
export const ADD_MY_REFERED_VOTERS_FAIL = "ADD_MY_REFERED_VOTERS_FAIL"

export const UPDATE_MY_REFERED_VOTERS = "UPDATE_MY_REFERED_VOTERS"
export const UPDATE_MY_REFERED_VOTERS_SUCCESS = "UPDATE_MY_REFERED_VOTERS_SUCCESS"
export const UPDATE_MY_REFERED_VOTERS_FAIL = "UPDATE_MY_REFERED_VOTERS_FAIL"

export const DELETE_MY_REFERED_VOTERS = "DELETE_MY_REFERED_VOTERS"
export const DELETE_MY_REFERED_VOTERS_SUCCESS = "DELETE_MY_REFERED_VOTERS_SUCCESS"
export const DELETE_MY_REFERED_VOTERS_FAIL = "DELETE_MY_REFERED_VOTERS_FAIL"

export const ON_ACTIVATE_DEACTIVATE_MY_REFERED_VOTERS = "ON_ACTIVATE_DEACTIVATE_MY_REFERED_VOTERS"
export const ON_ACTIVATE_DEACTIVATE_MY_REFERED_VOTERS_SUCCESS = "ON_ACTIVATE_DEACTIVATE_MY_REFERED_VOTERS_SUCCESS"
export const ON_ACTIVATE_DEACTIVATE_MY_REFERED_VOTERS_FAIL = "ON_ACTIVATE_DEACTIVATE_MY_REFERED_VOTERS_FAIL"



