import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Spinner, Input, Label, Button } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { BasicTable } from "../../Tables/DataTables/datatableCom";
import { columns } from "./DataTableColumns";
import SearchTextBox from "../../../Components/Common/SearchTextBox";
import { getVotersManagement, getVotersManagementTableColumnNames, activateDeactivateVotersManagement, resetVotersManagement } from "../../../store/votersManagement/actions";
import { getClasses } from "../../../store/classes/actions";
import Toaster from "../../../Components/Common/Toaster";


// let alphaData = [];
// let classNumber = "";

const VotersManagement = () => {
  const { t, i18n } = useTranslation();
  document.title = t('KW-Elections | Voters Management');
  const [optionsClass, setOptionsClass] = useState()
  const dispatch = useDispatch();
  const [data, setData] = useState();
  let user = JSON.parse(sessionStorage.getItem('auth'));
  const [ElectionDetail, setElectionDetail] = useState();
  const [voterNo, setVoterNo] = useState();
  const [voterName, setVoterName] = useState();

  const handleClass = (value) => {
    if (value !== '') {
      let module = ''
      ElectionDetail == 1 ? module = "VOTERSMANAGEMENT" : module = "VOTERMANAGEMENTCOPERATIVE";
      dispatch(getVotersManagement({ "classNo": value, ElectionID: user.ElectionID }));
      dispatch(getVotersManagementTableColumnNames({ module: module }));
    } else {
      dispatch(resetVotersManagement())
    }
  }

  function onActiveOrDeactiveChange(votersmanagement, e) {
    const votersObj = {}
    votersObj['_id'] = votersmanagement?._id;
    votersObj['VotersStatus'] = votersmanagement?.VotersStatus;
    if (votersmanagement.VotersStatus !== true) {
      let votedDateTime = new Date().toLocaleString();
      votedDateTime = votedDateTime.replaceAll('/', '-')
      votedDateTime = votedDateTime.replaceAll(',', '')
      votersObj['VotedDateTime'] = votedDateTime;
      dispatch(activateDeactivateVotersManagement(votersObj));
    } else {
      votersObj['VotedDateTime'] = "";
      dispatch(activateDeactivateVotersManagement(votersObj));
    }
  }

  const { Classes, isLoadingClasses, VotersManagement, isLoading, columnNames } = useSelector((state) => {
    return {
      Classes: state.Classes.classes,
      VotersManagement: state.VotersManagement.votersmanagement,
      columnNames: state.VotersManagement.columnNames,
      isLoading: state.VotersManagement.isLoading,
    }
  })

  const handleClear = () => {
    if (user.TypeId == 1)
      document.getElementById('alpha').value = ''
    document.getElementById('votersNo').value = ''
    document.getElementById('voterName').value = ''
    setData(VotersManagement)
  }

  const filterData = (data, filters) => {
    return data.filter((item) => {
      return Object.keys(filters).every((filter) => {
        const value = filters[filter].toString().toLowerCase();
        const itemValue = item[filter]?.toString().toLowerCase() || '';
        return itemValue.includes(value);
      });
    });
  };

  const handleFilterChange = () => {
    let filters = {};

    if (voterNo) {
      filters['VotersNo'] = voterNo;
    }
    if (voterName) {
      filters['FullName'] = voterName;
    }

    const filteredData = filterData(VotersManagement, filters);
    setData(filteredData);
  };

  const handleArabicCharacter = (value) => {
    const voterIdValue = document.getElementById('votersNo')?.value;
    const voterNameValue = document.getElementById('voterName')?.value;

    let filteredData = VotersManagement;
    // if (value !== "") {
    if (voterIdValue !== "") {
      filteredData = filterData(filteredData, 'VotersNo', voterIdValue);
    }
    if (voterNameValue !== "") {
      filteredData = filterData(filteredData, 'FullName', voterNameValue);
    }
    filteredData = filterData(filteredData, 'Alpha', value);
    // }

    setData(filteredData);
  };

  const handleVotersNo = (value) => {
    setVoterNo(value);
    let filteredData = VotersManagement;
    filteredData = filterData(filteredData, 'VotersNo', value);
    setData(filteredData);
  };

  const handleFullName = (value) => {
    setVoterName(value);
    const alphaValue = document.getElementById('alpha')?.value || '';
    const voterIdValue = document.getElementById('voterName')?.value || '';

    let filteredData = VotersManagement;

    // if (alphaValue !== "") {
    //   filteredData = filterData(filteredData, 'Alpha', alphaValue);
    // }
    // if (voterIdValue !== "") {
    //   filteredData = filterData(filteredData, 'VotersNo', voterIdValue);
    // }
    if (value !== "") {
      filteredData = filterData(filteredData, 'FullName', value);
    }

    setData(filteredData);
  };


  useEffect(() => {
    setData(VotersManagement)
  }, [VotersManagement]);

  useEffect(() => {
    if (user !== null) {
      dispatch(getClasses({ userID: user.id, ElectionID: user.ElectionID }));
    }
  }, [dispatch]);

  useEffect(() => {
    setOptionsClass(Classes);
  }, [dispatch, Classes]);

  useEffect(() => {
    handleFilterChange();
  }, [voterNo, voterName]);

  useEffect(() => {
    return () => {
      dispatch(resetVotersManagement())
    };
  }, []);

  useEffect(() => {
    setElectionDetail(user.TypeId)
  }, []);

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <Container fluid>
          {/* <h1>Booth Voting Component</h1> */}
          <Row className='mb-3'>
            <Col>
              <BreadCrumb title={t('Voters Management')} />
            </Col>
          </Row>

          <Row className='mb-3 voters-management-search-row'>
            <Col>
              <Label>{t('Class')}</Label>
              <Input
                name="arabic"
                type="select"
                className="form-select"
                id="voterClass"
                onChange={(e) => handleClass(e.target.value)}
              // onBlur={(e) => handleClass(e.target.value)}
              >
                <option value='' default>{t('Select')}</option>
                {
                  <React.Fragment>
                    {optionsClass?.map((item, key) => (<option value={item.ClassNo} key={item._id}>{item.ClassName}</option>))}
                  </React.Fragment>
                }
              </Input>
              {/* <DropDownTextBox initialData={dummyData} options={options} filter="voterAlphabet" setData={setData} /> */}
            </Col>

            {
              ElectionDetail == 1 ?
                <Col>
                  <Label>{t('Voter Alphabet')}</Label>
                  <Input
                    type="text"
                    className={i18n.language === 'ar' ? 'form-control float-start' : 'form-control float-end'}
                    placeholder={t('Search') + '...'}
                    id="alpha"
                    onChange={(e) => handleArabicCharacter(e.target.value)}
                  />
                </Col>
                :
                null
            }




            <Col>
              <Label>{t('Voter Number')}</Label>
              {/* <SearchTextBox initialData={data} filter="VotersNo" setData={setData} id="voterId" /> */}
              <Input type="text" className={i18n.language === 'ar' ? 'form-control float-start' : 'form-control float-end'} placeholder={t('Search') + '...'}
                id="votersNo"
                onChange={(e) => handleVotersNo(e.target.value)} />
            </Col>
            <Col>
              <Label>{t('Voter Name')}</Label>
              {/* <SearchTextBox initialData={data} filter="FullName" setData={setData} id="voterName" /> */}
              <Input type="text" className={i18n.language === 'ar' ? 'form-control float-start' : 'form-control float-end'} placeholder={t('Search') + '...'}
                id="voterName"
                onChange={(e) => handleFullName(e.target.value)} />
            </Col>
            <Col className="vmsr-btn">
              <Button onClick={handleClear} className="cis-width-120">{t('Clear')} <i className="ri-filter-off-line"></i></Button>
            </Col>
          </Row>

          {isLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spinner style={{
              height: '3rem',
              width: '3rem',
            }} className='me-2'> Loading... </Spinner>
          </div> :
            <Row>
              <Col>
                {(isLoadingClasses && isLoading) ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Spinner style={{
                    height: '3rem',
                    width: '3rem',
                  }} className='me-2'> Loading... </Spinner>
                </div> :
                  <BasicTable data={data} columns={columns(columnNames, i18n, t, onActiveOrDeactiveChange)} />
                }
              </Col>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VotersManagement;