
import { t } from "i18next";
import { Button, Input, InputGroup, InputGroupText } from "reactstrap";
import { filteredColumns } from "../../../helpers/Filter/FilterColumns";


export const columns = (columnNames, i18n, changeHandler, submitHandler) => {
	return filteredColumns(columnNames, i18n)?.map((column) => {
		let col;
		switch (column?.FieldName) {

			case 'Action':
				return col = {
					name: <span className='font-weight-bold fs-13'>{i18n.language === 'ar' ? column?.ValueAr : column?.ValueEn}</span>,
					selector: row => {
						console.log('row: ', row);
						return <Button onClick={() => submitHandler(row)}>
							{
								row.MobileNumber == "" ? t('Add') : t('Update')
							}
						</Button>
					}
				}

			case 'ClassNo':
				return col = {
					name: <span className='font-weight-bold fs-13'>{i18n.language === 'ar' ? column?.ValueAr : column?.ValueEn}</span>,
					selector: row => {
						return Number(row.ClassNo)
					},
					sortable: true,
					wrap: true
				}

			default:
				return col = {
					name: <span className='font-weight-bold fs-13'>{i18n.language === 'ar' ? column?.ValueAr : column?.ValueEn}</span>,
					selector: row => {
						return ['CreatedDate', 'ModifiedDate'].includes(column?.Title) ? new Date(row[column?.Title]).toDateString() : column?.Title === 'MobileNumber'
							?
							(<div className="form" >
								<InputGroup>
									<InputGroupText>+965</InputGroupText>
									<Input
										placeholder={t('Enter Mobile Number')}
										defaultValue={row?.MobileNumber?.replace('+965', '')}
										type="number"
										name="MobileNumber"
										maxLength={8}
										onChange={(e) => changeHandler(e, row)}
										onInput={(e) => {
											e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8); // Limit the input to 8 characters
										}}
									/>
								</InputGroup>

							</div>)
							: column?.Title === 'FullNameEnglish' ?
								(
									<div className="form" >
										<Input type="text"
											placeholder={t('Enter Full Name')}
											defaultValue={row.FullNameEnglish}
											name="FullName"
											onChange={(e) => { changeHandler(e, row) }}
										/>
									</div>
								)

								:
								column?.Title === 'FullNameArabic' ?
									(
										<div className="form" >
											<Input type="text"
												placeholder={t('Enter Full Name')}
												defaultValue={row.FullNameEnglish}
												name="FullName"
												onChange={(e) => { changeHandler(e, row) }}
											/>
										</div>
									)
									: column?.Title === 'Password' ?
										<Input
											placeholder={t('Enter Password')}
											type="text"
											name="Password"
											defaultValue={row.Password}
											maxLength={8}
											onChange={(e) => changeHandler(e, row)}
										/>
										:
										row[column?.Title]
					},
					sortable: true,
					wrap: true
				}
		}
	})
}



