import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useState } from 'react';

const filter = createFilterOptions();



export default function ListDropDown(listoptions, { onTypedValueChange }) {

    const [valuetyped, setValuetyped] = useState('');
    // console.log('valuetyped: ', valuetyped);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setValuetyped(newValue);
        onTypedValueChange(newValue);

    };

    const options = listoptions.listoptions;

    const [value, setValue] = React.useState(null);
    console.log('value: ', value);

    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    setValue({
                        FamilyName: newValue,
                    });
                } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setValue({
                        FamilyName: newValue.inputValue,
                    });
                } else {
                    setValue(newValue);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.FamilyName);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        FamilyName: `Add "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={options}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.FamilyName;
            }}
            renderOption={(props, option) => <li {...props}>{option.FamilyName}</li>}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} onChange={handleChange} />
            )}
        />
    );
}


const top100Films = [
    { FamilyName: 'Monty Python and the Holy Grail', year: 1975 },
];