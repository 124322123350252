import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, Row, Spinner, Label } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import AddModal from "../../../Components/Common/Modal/AddModal";
import SearchTextBox from "../../../Components/Common/SearchTextBox";
import Toaster from "../../../Components/Common/Toaster";
import { useTranslation } from "react-i18next";
import { columns } from "../DataTableColumns";
import { BasicTable } from "../../Tables/DataTables/datatableCom";
import { useDispatch, useSelector } from "react-redux";
import { getRoleWiseReport, getRoleWisTableColumnNames } from "../../../store/analyticalReport/actions";
import { getPrintDetail } from "../../../store/voters/actions";
import { CSVLink } from 'react-csv';
import { Link, useLocation } from "react-router-dom";
import yasaLight from '../../../assets/images/horizonatal-view.png';
import { getRoles } from "../../../store/actions";

const RoleWiseReport = () => {
    const { t, i18n } = useTranslation();
    document.title = t('KW-Elections | Role Wise Report');
    const Auth = JSON.parse(sessionStorage.getItem('auth'));
    console.log('Auth: ', Auth);
    const language = localStorage.getItem("I18N_LANGUAGE");
    const dispatch = useDispatch();
    const state = useLocation();
    const [query, setQuery] = useState(null);

    const role = state?.state?.status ? Number(state?.state?.status) : Auth.RoleID ;

    const { RoleWise, isLoading, columnNames, printDetail } = useSelector((state) => {
        return {
            RoleWise: state.AnalyticalReports.roleWise,
            columnNames: state.AnalyticalReports.roleWiseColumnsNames,
            isLoading: state.AnalyticalReports.isLoading,
            printDetail: state.Voters.printDetail[0]
        }
    });

    const { Roles } = useSelector((state) => ({
        Roles: state.Roles.roles,
    }));



    console.log('Roles: ', Roles);


    const [data, setData] = useState(RoleWise);

    useEffect(() => {
        setData(RoleWise)
    }, [RoleWise]);

    let user = sessionStorage.getItem('auth')
    user = JSON.parse(user);

    useEffect(() => {
        dispatch(getRoleWisTableColumnNames())
        dispatch(getRoles({ RoleID: Auth.RoleID, ElectionID: user.ElectionID }));
    }, [])

    useEffect(() => {
        setQuery(role);
        dispatch(getRoleWiseReport({ UserID: user.id, role: Number(role) }))
    }, [role])

    useEffect(() => {
        dispatch(getRoleWiseReport({ UserID: user.id, role: Number(query) }))
        //  dispatch(getPrintDetail({ userID: user.id }));
    }, [dispatch, query]);



    const printTable = (e, columns) => {
        e.preventDefault();
        let date = new Date().toLocaleString();
        const newWindow = window.open();
        newWindow.document.write('<html><head>');
        newWindow.document.write('<style>table { border-collapse: collapse; width: 100%; }');
        newWindow.document.write('th, td { border: 1px solid black; padding: 8px; text-align: center; font-size: 12px; }</style>');
        newWindow.document.write('<style>th { background-color: #f2f2f2; }</style>');
        newWindow.document.write('<style>tr:nth-child(even) { background-color: #f2f2f2; }</style>');
        newWindow.document.write('<style>tr:hover { background-color: #ddd; }</style>');
        newWindow.document.write(`<style>title { text-align: right; }</style><title>Role Wise List</title>`);
        newWindow.document.write('<style>.logo-conStainer { display: flex; justify-content: space-between; margin-bottom: 10px; }</style>');
        newWindow.document.write('</head><body>');
        if (printDetail?.CandidateName != undefined && printDetail?.ElectionName != undefined) {
            newWindow.document.write(`<div class="logo-container" >
          <div>
          <p>${printDetail?.CandidateName}</p>
          <p>${printDetail?.ElectionName}</p>
          </div>
          <div>
            <h3>قائمة الحكمة لاسم المنطقة</h3>
          </div>
          <div>
            <img style="margin-top: 20px" src=${yasaLight} onload="window.print()" width="200px" height="33px" />
          </div>
        </div>`);
        }
        //   else {
        //     newWindow.document.write(`<div class="logo-container" >
        //   <div>
        //   <p>Candidate Name :- ______</p>
        //   <p>Election Name :- ______</p>
        //   </div>
        //   <div>
        //   <h2 style="text-align: left;">Refer Voter List</h2>
        //   <img src=${yasaLight} onload="window.print()" width="200px" height="33px" />
        //   </div>
        // </div>`);
        //   }


        // Set page orientation to landscape
        // newWindow.document.write('<style>@page  { size: landscape; }</style>');
        //     newWindow.document.write(`<style type="text/css" media="print">
        //   @page {
        //     size: auto;  
        //     margin: 0; 
        //     margin-top: 10;
        //     margin-bottom: 15;
        //   }
        // </style>`)


        newWindow.document.write(`<style>
        @media print {
          @page {
            margin-top: 0.5px; 
          }
        }
        </style>`)
        newWindow.document.write('</head><body>');
        newWindow.document.write('<table>');
        newWindow.document.write(`<thead><tr>${columns?.map((col) => `<th>${col?.name?.props?.children}</th>`).join('')}</tr></thead>`);
        newWindow.document.write('<tbody>');
        RoleWise?.forEach((row) => {
            newWindow.document.write(`<tr>${columns?.map((col) => {
                const value = col?.selector && typeof col.selector === 'function' ? col.selector(row) : '';
                if (col?.name?.props?.children === 'Active') {
                    let isActive = false;
                    if (value?.props?.checked) {
                        isActive = true;
                    } else if (Array.isArray(value?.props?.children)) {
                        isActive = value.props.children.some(child => child.props.checked);
                    }
                    return `<td>${isActive ? 'true' : 'false'}</td>`;
                } else if (typeof value === 'object' && value !== null) {
                    if (value.text) {
                        return `<td>${value.text}</td>`;
                    } else if (value.props && value.props.children) {
                        return `<td>${value.props.children}</td>`;
                    } else {
                        return `<td>${JSON.stringify(value)}</td>`;
                    }
                } else {
                    return `<td>${value}</td>`;
                }
            }).join('')}</tr>`);
        });
        newWindow.document.write('</tbody></table></body>')
        // newWindow.document.write(`<p>${printDetail?.FullName}</html>`);
        if (printDetail?.FullNameArabic != undefined) {
            newWindow.document.write(`<footer
        style="position: fixed;
         left: 5;
         bottom: 0;
         width: 100%;
         background-color: white;
         color: black;
         text-align: left;"
        >
        <div>
          ${printDetail?.FullNameArabic}
        </div>
        <div style="font-size: smaller">
          ${date}
        </div>
        </footer>`);
        }
        // else {
        //   newWindow.document.write(`<footer
        // style="position: fixed;
        //  left: 5;
        //  bottom: 0;
        //  width: 100%;
        //  background-color: white;
        //  color: black;
        //  text-align: left;"
        // >Print By :- ______</footer>`);
        // }
        newWindow.document.write('</html>');
        newWindow.document.write('<style>tr:nth-child(odd) { background-color: #ffffff; }</style>');
        newWindow.document.write('<style>tr:nth-child(even) { background-color: #f2f2f2; }</style>');
    };

    const exportToCsv = (data, columns) => {
        const csvData = data?.map((row) => {
            const csvRow = {};
            columns?.forEach((col) => {
                const value = col?.selector && typeof col.selector === 'function' ? col.selector(row) : '';
                if (col?.name?.props?.children === 'Active') {
                    let isActive = false;
                    if (value?.props?.checked) {
                        isActive = true;
                    } else if (Array.isArray(value?.props?.children)) {
                        isActive = value.props.children.some((child) => child.props.checked);
                    }
                    csvRow[col.name.props.children] = isActive ? 'true' : 'false';
                } else if (typeof value === 'object' && value !== null) {
                    if (value.text) {
                        csvRow[col.name.props.children] = value.text;
                    } else if (value.props && value.props.children) {
                        csvRow[col.name.props.children] = value.props.children;
                    } else {
                        csvRow[col.name.props.children] = JSON.stringify(value);
                    }
                } else {
                    csvRow[col.name.props.children] = value;
                }
            });
            return csvRow;
        });
        const csvHeaders = columns?.map((col) => col.name.props.children);
        return (
            <CSVLink data={csvData} headers={csvHeaders} filename="RoleWiseRepportw.csv">
                <button type="button" className="btn btn-primary ml-4">
                    <i className="ri-file-excel-2-line align-bottom me-1"></i>
                    {t('Export to csv')}
                </button>
            </CSVLink>
        );
    };

    return (

        <React.Fragment>
            <Toaster />
            <div className="page-content">
                <Container fluid>
                    <Row className='mb-3'>
                        <Col>
                            <BreadCrumb title={t('Role Wise Report')} />
                        </Col>
                    </Row>
                    <Row className='mb-3 area-wise-report-row'>

                        <Col>
                            <label> &nbsp;</label> <br />
                            <div className="cis-manage-demo-btn">
                                <Link to="#" onClick={(e) => { printTable(e, columns(columnNames, i18n, t)) }} className="btn btn-success mr-15px" style={{
                                }}>
                                    <i className="ri-printer-line align-bottom me-1"></i>
                                    {t('Print')}
                                </Link>
                                {exportToCsv(data, columns(columnNames, i18n, t))}
                            </div>
                        </Col>
                        <Col>
                            <Label>{t('')}</Label>
                            <Input type="select" name="AreaID" className="form-select" id="areaName" onChange={(e) => setQuery(e.target.value)} >
                                <option value={query} default>{t('Select')}</option>
                                {
                                    <React.Fragment>
                                        {
                                            Roles?.map((item, key) => (
                                                <option value={item.RoleID} defaultChecked={query} key={item._id}>
                                                    {
                                                        language == 'ar' ? item.RoleNameArabic : item.RoleNameEnglish
                                                    }</option>)
                                            )
                                        }
                                    </React.Fragment>
                                }
                            </Input>
                        </Col>

                        <Col>
                            <label> &nbsp;</label> <br />
                            <SearchTextBox initialData={RoleWise} setData={setData} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Col>
                                {isLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Spinner style={{
                                        height: '3rem',
                                        width: '3rem',
                                    }} className='me-2'> Loading... </Spinner>
                                </div> : <BasicTable data={data} columns={columns(columnNames, i18n, t)} />}
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </div>
            <AddModal />
            <DeleteModal />
        </React.Fragment>
    )
}

export default RoleWiseReport;