export const GET_CANDIDATES = "GET_CANDIDATES";
export const GET_CANDIDATES_SUCCESS = "GET_CANDIDATES_SUCCESS";
export const GET_CANDIDATES_FAIL = "GET_CANDIDATES_FAIL";


export const GET_CANDIDATES_TABLE_COLUMN_NAMES = "GET_CANDIDATES_TABLE_COLUMN_NAMES"
export const GET_CANDIDATES_TABLE_COLUMN_NAMES_SUCCESS = "GET_CANDIDATES_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_CANDIDATES_TABLE_COLUMN_NAMES_FAIL = "GET_CANDIDATES_TABLE_COLUMN_NAMES_FAIL"

export const ADD_CANDIDATES = "ADD_CANDIDATES"
export const ADD_CANDIDATES_SUCCESS = "ADD_CANDIDATES_SUCCESS"
export const ADD_CANDIDATES_FAIL = "ADD_CANDIDATES_FAIL"

export const UPDATE_CANDIDATES = "UPDATE_CANDIDATES"
export const UPDATE_CANDIDATES_SUCCESS = "UPDATE_CANDIDATES_SUCCESS"
export const UPDATE_CANDIDATES_FAIL = "UPDATE_CANDIDATES_FAIL"

export const DELETE_CANDIDATES = "DELETE_CANDIDATES"
export const DELETE_CANDIDATES_SUCCESS = "DELETE_CANDIDATES_SUCCESS"
export const DELETE_CANDIDATES_FAIL = "DELETE_CANDIDATES_FAIL"

export const ON_ACTIVATE_DEACTIVATE_CANDIDATES = "ON_ACTIVATE_DEACTIVATE_CANDIDATES"
export const ON_ACTIVATE_DEACTIVATE_CANDIDATES_SUCCESS = "ON_ACTIVATE_DEACTIVATE_CANDIDATES_SUCCESS"
export const ON_ACTIVATE_DEACTIVATE_CANDIDATES_FAIL = "ON_ACTIVATE_DEACTIVATE_CANDIDATES_FAIL"

export const UPLOAD_CANDIDATE_PROFILE = "UPLOAD_CANDIDATE_PROFILE";
export const UPLOAD_CANDIDATE_PROFILE_SUCCESS = "UPLOAD_CANDIDATE_PROFILE_SUCCESS";
export const UPLOAD_CANDIDATE_PROFILE_FAIL = "UPLOAD_CANDIDATE_PROFILE_FAIL";



