import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { checkPhoneNumber, getElectionDetails, getElections } from "../../store/actions";
import outerLogo from '../../assets/images/outerLogo.png'
import withRouter from "../../Components/Common/withRouter";
//import images
import { get, map } from "lodash";
import languages from '../../common/languages';
import { useTranslation } from 'react-i18next';
import { RecaptchaVerifier, signInWithPhoneNumber, getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, } from "firebase/auth";
import { auth } from "../../helpers/firebaseConfig";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import OtpModal from '../../Components/Common/OtpModal';
import Toaster from '../../Components/Common/Toaster';
import { ToastContainer, toast } from "react-toastify";



const Login = (props) => {
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const [userLogin, setUserLogin] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);
    const [selectedLang, setSelectedLang] = useState("");
    const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
    const [otp, setOtp] = useState("");
    const [ph, setPh] = useState("");
    const [loading, setLoading] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [user, setUser] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [otpErrorMsg, setOTPErrorMsg] = useState('');
    const [election, setElection] = useState({})
    const [electionID, setElectionID] = useState()
    const [password, setPassword] = useState();

    // useEffect(() => {
    //     if (!window.recaptchaVerifier) {

    //         window.recaptchaVerifier = new RecaptchaVerifier(
    //             "recaptcha-container",
    //             {
    //                 size: "invisible",
    //                 callback: (response) => {

    //                 },
    //                 "expired-callback": () => { },
    //             },
    //             auth
    //         );
    //     }  
    // })

    const { Phone, Elections } = useSelector((state) => ({
        Phone: state.Phone.phone,
        Elections: state.Elections.elections,

    }));

    const setPs = (e) => {
        setPassword(e.target.value)
    }

    async function onSignup(e) {
        e.preventDefault();
        // setLoading(false);
        setLoading(true);

        console.log('electionID: ', electionID);
        let payload = {
            MobileNumber: '+965' + ph,
            ElectionID: electionID,
        };
        dispatch(checkPhoneNumber(payload));

    }

    async function onOTPVerify() {

        try {
            let email = Phone[0]?.Email
            const res = await signInWithEmailAndPassword(auth, email, password);

            const responseObj = {
                emailVerified: res.user.emailVerified,
                isAnonymous: res.user.isAnonymous,
                phoneNumber: res.user.phoneNumber,
                RoleID: Phone[0].RoleID,
                CandidateID: Phone[0]?.CandidateID ? Phone[0]?.CandidateID : null,
                id: Phone[0]._id,
                FullNameEnglish: Phone[0].FullNameEnglish,
                FullNameArabic: Phone[0].FullNameArabic,
                idToken: res._tokenResponse.idToken,
                LinkID: Phone[0].LinkID ? Phone[0]?.LinkID : null,
                ElectionID: Phone[0].ElectionID,
                TypeId: Phone[0].TypeId,
                CandidateNameArabic: Phone[0]?.CandidateNameArabic ? Phone[0]?.CandidateNameArabic : Phone[0].FullNameEnglish,
                CandidateNameEnglish: Phone[0]?.CandidateNameEnglish ? Phone[0]?.CandidateNameEnglish : Phone[0].FullNameArabic,
                Profile: Phone[0]?.Profile
            };


            if (res.user) {
                sessionStorage.setItem('auth', JSON.stringify(responseObj));
                props.router.navigate('/dashboard');
                setUser(res.user);
                toast.success("Logged in Successfully")
                setLoading(false)
            } else {
                props.router.navigate('/login');
                setLoading(false)
            }

        } catch (err) {
            setLoading(false)
            if (err.code == 'auth/user-not-found') {
                toast.error('User Not Found')
            }
            else if (err.code == 'auth/wrong-password') {
                toast.error("Invalid Credentials")
            }
            else if (err.code == 'auth/too-many-requests') {
                toast.error("Too Many Attempts")
            }
            else {
                toast.error("Network Error")
            }
        }
    }

    useEffect(() => {
        if (Phone.length) {
            onOTPVerify()
        }
    }, [Phone])

    useEffect(() => {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
        setSelectedLang(!currentLanguage ? 'en' : currentLanguage);
        setLoading(true)
        dispatch(getElections());
    }, []);

    useEffect(() => {
        //dispatch(getElections());
    }, [dispatch])

    useEffect(() => {
        // const defaultElection = Elections.find((election) => election.Default === true);
        // if (defaultElection) {
        //     setElection({ _id: defaultElection._id, ElectionNameArabic: defaultElection.ElectionNameArabic });
        //     setElectionID({ ElectionID: defaultElection._id })
        // }

        setElection(Elections[0])
        setElectionID(Elections[0]?._id);
        setLoading(false)
    }, [Elections]);

    const changeLanguageAction = lang => {
        //set language as i18n
        localStorage.setItem("I18N_LANGUAGE", lang);
        i18n.changeLanguage(lang);
        document.body.dir = i18n.dir();
        setSelectedLang(lang);

    };

    const toggleLanguageDropdown = () => {
        setIsLanguageDropdown(!isLanguageDropdown);
    };

    const getElectionID = (e) => {
        console.log('e: ', e.target.value);
        setElectionID(e.target.value)
    }

    document.title = t('SignIn | KW-Elections');

    return (
        <React.Fragment>
            <Toaster />
            {/* <OtpModal
                otp={otp}
                showDelete={showDelete}
                setShowDelete={setShowDelete}
                otpErrorMsg={otpErrorMsg}
                onOTPVerify={onOTPVerify}
                setOtp={setOtp}
            /> */}
            <ParticlesAuth>
                <div id="recaptcha-container"></div>

                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        {/* { <Link to="/" className="d-inline-block auth-logo">} */}
                                        <img src={outerLogo} alt="" height="100" />
                                        {/* { </Link>} */}
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium"></p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">{t('Welcome Back !')}</h5>
                                            <p className="text-muted">{t('Sign in to continue')}</p>
                                        </div>
                                        <div className="p-2 mt-4">

                                            <Form onSubmit={(e) => { onSignup(e) }}>
                                                <Toaster />
                                                <div className="mb-3">
                                                    <Label htmlFor="phone" className="form-label">{t('Mobile Number')}</Label>
                                                    <input
                                                        maxLength="8"
                                                        onChange={(e) => setPh(e.target.value)}
                                                        className='form-control'
                                                    />

                                                </div>


                                                <div className="mb-3">
                                                    <Label htmlFor="password" className="form-label">{t('Password')}</Label>
                                                    <Input type='password' onChange={setPs}></Input>
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="election" className="form-label">{t('Elections')}</Label>
                                                    <select className='form-control' name="ElectionID" onChange={(e) => getElectionID(e)}>

                                                        {
                                                            Elections.map((election) => {
                                                                <option defaultValue={election?.Default && election?._id} >
                                                                    {election?.Default && election?.ElectionNameArabic}
                                                                </option>

                                                                return (
                                                                    <option key={election._id} value={election._id}>
                                                                        {election.ElectionNameArabic}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>

                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <Button color="success" className="btn btn-success w-100" type="submit">
                                                        {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                        {t('Login')}
                                                        <i className="ri-phone-lock-line"></i>
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>

                                        <Dropdown isOpen={isLanguageDropdown} toggle={toggleLanguageDropdown} className="ms-1 topbar-head-dropdown header-item">
                                            <span>{t('Language')}</span>
                                            <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
                                                <img
                                                    src={get(languages, `${selectedLang}.flag`)}
                                                    alt="Header Language"
                                                    height="20"
                                                    className="rounded"
                                                />
                                            </DropdownToggle>
                                            <DropdownMenu className="notify-item language py-2">
                                                {map(Object.keys(languages), key => (
                                                    <DropdownItem
                                                        key={key}
                                                        onClick={() => changeLanguageAction(key)}
                                                        className={`notify-item ${selectedLang === key ? "active" : "none"
                                                            }`}
                                                    >
                                                        <img
                                                            src={get(languages, `${key}.flag`)}
                                                            alt="Skote"
                                                            className="me-2 rounded"
                                                            height="18"
                                                        />
                                                        <span className="align-middle">
                                                            {get(languages, `${key}.label`)}
                                                        </span>
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>)
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);