export const GET_BOOTHUSERS = "GET_BOOTHUSERS";
export const GET_BOOTHUSERS_SUCCESS = "GET_BOOTHUSERS_SUCCESS";
export const GET_BOOTHUSERS_FAIL = "GET_BOOTHUSERS_FAIL";


export const GET_BOOTHUSERS_TABLE_COLUMN_NAMES = "GET_BOOTHUSERS_TABLE_COLUMN_NAMES"
export const GET_BOOTHUSERS_TABLE_COLUMN_NAMES_SUCCESS = "GET_BOOTHUSERS_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_BOOTHUSERS_TABLE_COLUMN_NAMES_FAIL = "GET_BOOTHUSERS_TABLE_COLUMN_NAMES_FAIL"

export const ADD_BOOTHUSERS = "ADD_BOOTHUSERS"
export const ADD_BOOTHUSERS_SUCCESS = "ADD_BOOTHUSERS_SUCCESS"
export const ADD_BOOTHUSERS_FAIL = "ADD_BOOTHUSERS_FAIL"

export const UPDATE_BOOTHUSERS = "UPDATE_BOOTHUSERS"
export const UPDATE_BOOTHUSERS_SUCCESS = "UPDATE_BOOTHUSERS_SUCCESS"
export const UPDATE_BOOTHUSERS_FAIL = "UPDATE_BOOTHUSERS_FAIL"

export const DELETE_BOOTHUSERS = "DELETE_BOOTHUSERS"
export const DELETE_BOOTHUSERS_SUCCESS = "DELETE_BOOTHUSERS_SUCCESS"
export const DELETE_BOOTHUSERS_FAIL = "DELETE_BOOTHUSERS_FAIL"

export const ON_ACTIVATE_DEACTIVATE_BOOTHUSERS = "ON_ACTIVATE_DEACTIVATE_BOOTHUSERS"
export const ON_ACTIVATE_DEACTIVATE_BOOTHUSERS_SUCCESS = "ON_ACTIVATE_DEACTIVATE_BOOTHUSERS_SUCCESS"
export const ON_ACTIVATE_DEACTIVATE_BOOTHUSERS_FAIL = "ON_ACTIVATE_DEACTIVATE_BOOTHUSERS_FAIL"



