export const GET_AREAWISE_REPORT = "GET_AREAWISE_REPORT";
export const GET_AREAWISE_REPORT_SUCCESS = "GET_AREAWISE_REPORT_SUCCESS";
export const GET_AREAWISE_REPORT_FAIL = "GET_AREAWISE_REPORT_FAIL";

export const GET_ROLEWISE_REPORT = "GET_ROLEWISE_REPORT";
export const GET_ROLEWISE_REPORT_SUCESS = "GET_ROLEWISE_REPORT_SUCESS";
export const GET_ROLEWISE_REPORT_FAIL = "GET_ROLEWISE_REPORT_FAIL";

export const GET_AREAWISE_TABLE_COLUMN_NAMES = "GET_AREAWISE_TABLE_COLUMN_NAMES";
export const GET_AREAWISE_TABLE_COLUMN_NAMES_SUCCESS = "GET_AREAWISE_TABLE_COLUMN_NAMES_SUCCESS";
export const GET_AREAWISE_TABLE_COLUMN_NAMES_FAIL = "GET_AREAWISE_TABLE_COLUMN_NAMES_FAIL";

export const GET_ROLEWISE_TABLE_COLUMN_NAMES = "GET_ROLEWISE_TABLE_COLUMN_NAMES";
export const GET_ROLEWISE_TABLE_COLUMN_NAMES_SUCCESS = "GET_ROLEWISE_TABLE_COLUMN_NAMES_SUCCESS";
export const GET_ROLEWISE_TABLE_COLUMN_NAMES_FAIL = "GET_ROLEWISE_TABLE_COLUMN_NAMES_FAIL";

export const GET_SCHOOLWISE_REPORT = "GET_SCHOOLWISE_REPORT";
export const GET_SCHOOLWISE_REPORT_SUCCESS = "GET_SCHOOLWISE_REPORT_SUCCESS";
export const GET_SCHOOLWISE_REPORT_FAIL = "GET_SCHOOLWISE_REPORT_FAIL";

export const GET_SCHOOLWISE_TABLE_COLUMN_NAMES = "GET_SCHOOLWISE_TABLE_COLUMN_NAMES"
export const GET_SCHOOLWISE_TABLE_COLUMN_NAMES_SUCCESS = "GET_SCHOOLWISE_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_SCHOOLWISE_TABLE_COLUMN_NAMES_FAIL = "GET_SCHOOLWISE_TABLE_COLUMN_NAMES_FAIL"

export const GET_BOOTHWISE_REPORT = "GET_BOOTHWISE_REPORT";
export const GET_BOOTHWISE_REPORT_SUCCESS = "GET_BOOTHWISE_REPORT_SUCCESS";
export const GET_BOOTHWISE_REPORT_FAIL = "GET_BOOTHWISE_REPORT_FAIL";

export const GET_BOOTHWISE_TABLE_COLUMN_NAMES = "GET_BOOTHWISE_TABLE_COLUMN_NAMES"
export const GET_BOOTHWISE_TABLE_COLUMN_NAMES_SUCCESS = "GET_BOOTHWISE_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_BOOTHWISE_TABLE_COLUMN_NAMES_FAIL = "GET_BOOTHWISE_TABLE_COLUMN_NAMES_FAIL"

export const GET_FAMILYNAMEWISE_REPORT = "GET_FAMILYNAMEWISE_REPORT";
export const GET_FAMILYNAMEWISE_REPORT_SUCCESS = "GET_FAMILYNAMEWISE_REPORT_SUCCESS";
export const GET_FAMILYNAMEWISE_REPORT_FAIL = "GET_FAMILYNAMEWISE_REPORT_FAIL";

export const GET_FAMILYNAMEWISE_TABLE_COLUMN_NAMES = "GET_FAMILYNAMEWISE_TABLE_COLUMN_NAMES"
export const GET_FAMILYNAMEWISE_TABLE_COLUMN_NAMES_SUCCESS = "GET_FAMILYNAMEWISE_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_FAMILYNAMEWISE_TABLE_COLUMN_NAMES_FAIL = "GET_FAMILYNAMEWISE_TABLE_COLUMN_NAMES_FAIL"