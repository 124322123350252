import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Spinner, Label, Input, Button, Dropdown } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import SearchTextBox from "../../../Components/Common/SearchTextBox";
import Toaster from "../../../Components/Common/Toaster";
import {
  getReferVoters,
  getReferVotersTableColumnNames,
  addReferVoters,
  updateMyReferedVoters,
  updateReferVoters,
  deleteMyReferedVoters,
  deleteReferVoters,
} from "../../../store/actions";

import { getAreaName, getFamilyName } from "../../../store/voters/actions";
import { BasicTable, TableWithCustomPagination } from "../../Tables/DataTables/datatableCom";
import { columns } from "./DataTableColumns";
import ReferVoterModal from "../../../Components/Common/ReferVoterModal";
import EditReferVoterModal from "../../../Components/Common/EditReferVoterModal";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";
import Table from "./DataTable";
import { DayTableSlicer } from "@fullcalendar/daygrid";
import DeleteModal from "../../../Components/Common/DeleteModal";

// import { getReferVoters } from "../../../helpers/fakebackend_helper";

const ReferVoters = () => {
  const { t, i18n } = useTranslation();
  const state = useLocation();
  document.title = t("KW-Elections | Refer Voters");
  const authUser = JSON.parse(sessionStorage.getItem("auth"));
  let user = sessionStorage.getItem('auth')
  user = JSON.parse(user)

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [showReferModal, setShowReferModal] = useState(false);
  const [showEditReferModal, setShowEditReferModal] = useState(false);
  const [toAddVoter, setToAddVoter] = useState();
  const [isAddOrEdit, setIsAddOrEdit] = useState();
  const [detailsByCurrentUser, setDetailsByCurrentUser] = useState();
  const [searchedFamilyName, setSearchedFamilyName] = useState([]);
  const [searchWord, setSearchWord] = useState();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteRow, setDeleteRow] = useState();
  const [page, setPage] = useState(1);
  const [test, setTest] = useState(false);
  const [drun, setDrun] = useState(false);
  const [Load, setLoad] = useState(false)


  function RefetchList() {
    setTimeout(() => {
      setTest(true);
    }, 2000)

  }
  const currentUser = authUser?.id;

  const referVotersList = useSelector(
    ({ ReferVoters }) => ReferVoters?.referVoters
  );
  const columnNames = useSelector(
    ({ ReferVoters }) => ReferVoters?.columnNames
  );
  const isLoading = useSelector(({ ReferVoters }) => ReferVoters?.isLoading);

  const addReferVoterHandler = (referVoter, mode) => {
    setIsAddOrEdit(mode);
    setToAddVoter(referVoter._id);
    if (mode === "edit") {
      const modalDetails = referVotersList.Data?.find(
        (el) => el._id === referVoter._id
      )?.ReferBy;

      const modaldetail = modalDetails?.find(
        (el) => el?.ReferID === currentUser
      );
      setDetailsByCurrentUser(modaldetail);
      setShowEditReferModal(true);
    } else setShowReferModal(true);
  };

  const [searchQuery, setSearchQuery] = useState({
    FullName: "",
    FamilyName: "",
    AreaID: "",
    SexCode: "",
    VotersNo: "",
    VotersStatus: `${state?.state?.status}`
  })


  const [familyNameList, setFamilyNameList] = useState([])
  const [areaNameList, setAreaNameList] = useState([]);
  const [options, setOptions] = useState([]);


  const { areaName, familyName } = useSelector((state) => {
    return {
      areaName: state.Voters.areaName,
      familyName: state.Voters.familyName,
    }
  });
  const onSaveReferClick = (referedVoterDetails) => {
    let data = {
      ...referedVoterDetails,
      UserID: toAddVoter,
      ReferID: currentUser,
    };
    // ReferName:
    if (isAddOrEdit === "add") {
      const x = dispatch(addReferVoters(data));
      data = {}
      setShowReferModal(false);
      setLoad(true)
      
    } else {
      dispatch(updateReferVoters(data));
      setShowEditReferModal(false);
      data = {}
    }
  };

  useEffect(() => {
    dispatch(getAreaName({ userID: user.id }))
  }, [])

  useEffect(() => {
    let module = ""
    user.TypeId == 1 ? module = "REFERVOTERS" : module = "REFERVOTERSCOPERATIVE";


    dispatch(getReferVoters({ searchQuery, page: page, UserID: user.id, }));
    dispatch(getReferVotersTableColumnNames({ module: module }));

    if (Load) {
      setLoad(false)
    }
  }, [dispatch, drun, page, test]);


  const handleChange = (e) => {
    setSearchQuery((preValue) => ({
      ...preValue,
      [e.target.name]: e.target.value,

    }));
  }

  const handleSearch = () => {
    dispatch(getReferVoters({ searchQuery, page: page, UserID: user.id }))
  }

  const handleClear = () => {

    document.getElementById('fullName').value = ''
    document.getElementById('familyName').value = ''
    document.getElementById('gender').value = ''
    if (user.TypeId == 1) { document.getElementById('areaName').value = '' }
    if (user.TypeId == 2) { document.getElementById('VotersNo').value = '' }

    setSearchQuery({
      UserID: user.id,
      FullName: "",
      FamilyName: "",
      AreaID: "",
      SexCode: "",
      VotersNo: "",
      VotersStatus: "",
    })
    setPage(1)

    dispatch(getReferVoters({
      searchQuery: {
        UserID: user.id,
        FullName: "",
        FamilyName: "",
        AreaID: "",
        SexCode: "",
        VotersNo: "",
        VotersStatus: "",
      }, page: page, UserID: user.id
    }))
  }

  const handleNext = () => {
    setPage(() => page + 1);
  };

  const handlePrev = () => {
    setPage(() => Math.max(page - 1, 1)); // Ensure page doesn't go below 1
  };

  const onDeleteClickHandler = (myReferVoters) => {
    setShowDelete(true);
    setDeleteRow(myReferVoters._id);
  };

  const onDeleteClick = () => {
    const referedBy = JSON.parse(sessionStorage.getItem("auth"))?.id;
    const data = { RFID: referedBy, UserID: deleteRow };
    dispatch(deleteReferVoters(data));
    setShowDelete(false);
    // setTimeout(() => {
    //   setDrun(!drun)
    // }, 2000)
  };

  useEffect(() => {
    setData(referVotersList);
    setAreaNameList(areaName);
    setFamilyNameList(familyName)
    const result = familyNameList.map(item => ({
      name: item.FamilyName,
      value: item._id,
    }));
    setOptions(result);
  }, [referVotersList]);


  const handleSearchFamilyName = (e) => {
    let srchName = e.target.value;
    setSearchWord(e.target.value);
    let nameList = familyNameList.filter((item) => {
      return item.FamilyName.includes(srchName);
    })
    setSearchedFamilyName(nameList);
  }


  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <Container fluid>
          <Row className="mb-3">
            <Col>
              <BreadCrumb title={t("Refer Voters")} />
            </Col>
          </Row>

          <Row className="mb-3 refer-voters-row">


            <Col>
              <Label>{t('Full Name')}</Label>

              <Input
                type="text"
                className={i18n.language === 'ar' ? 'form-control float-start' : 'form-control float-end'}
                placeholder={t('Search') + '...'}
                id="fullName" name="FullName"
                onChange={(e) => handleChange(e)} />
            </Col>

            <Col>
              <Label>{t('Family Name')}</Label>
              {/* <SearchTextBox initialData={VotersManagement} setData={setData} id="alpha" /> */}

              {/* {<Input type="text" className={i18n.language === 'ar' ? 'form-control float-start' : 'form-control float-end'}
                placeholder={t('Search') + '...'}
               // onChange={handleSearchFamilyName}
                id="familyName" name="FamilyName"
                onChange={(e) => handleChange(e)}  >
              </Input> */}



              {/* {<Dropdown>
                <Dropdown.Toggle className="menu-dropdown" id="dropdown-basic">
                  <form>
                    <input type='text' id="familyName" name="FamilyName" value={searchWord} onChange={handleSearchFamilyName}
                    className={i18n.language === 'ar' ? 'form-control float-start' : 'form-control float-end'}
                      placeholder={t('Search') + '...'} />
                  </form>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {searchedFamilyName
                    && (searchedFamilyName?.map((item, i) => {
                      return (<Dropdown.Item key={i} eventKey={item.FamilyName}
                        aria-selected='true'>
                        {item.FamilyName}
                      </Dropdown.Item>)
                    })
                    )
                  }
                </Dropdown.Menu>
              </Dropdown>} */}

              {/* <select>
                <option value='' default>{t('Select')}</option>
                {searchedFamilyName.map((item) => {
                  return <option value={item.FamilyName} key={item._id}>{item.FamilyName}</option>
                })
                }
              </select> */}
              {/* <select>
                {searchedFamilyName.map((item) => {
                  return <option value={item.FamilyName} key={item._id}>{item.FamilyName}</option>
                })
                }
              </select> }

            </Col>

            <Col>
              <Label>{t('Family Name')}</Label>


              {/* <Input type="select" name="FamilyName" className="form-select" id="familyName" onChange={(e) => handleChange(e)} >
                <option value='' default>{t('Select')}</option>
                {
                  <React.Fragment>
                    {familyNameList?.map((item, key) => (<option value={item.FamilyName} key={item._id}>{item.FamilyName}</option>))}
                  </React.Fragment>
                }
              </Input> */}



              <Input
                type="text"
                className={i18n.language === 'ar' ? 'form-control float-start' : 'form-control float-end'}
                placeholder={t('Search') + '...'}
                id="familyName"
                name="FamilyName"
                onChange={(e) => handleChange(e)}
              />


            </Col>

            {
              user.TypeId == 1 ?
                <Col>
                  <Label>{t('Area Name')}</Label>
                  <Input type="select" name="AreaID" className="form-select" id="areaName" onChange={(e) => handleChange(e)} >
                    <option value='' default>{t('Select')} </option>
                    {
                      <React.Fragment>
                        {areaNameList?.map((item, key) => (<option value={item.ID} key={item._id}>{item.AreaName}</option>))}
                      </React.Fragment>
                    }
                  </Input>
                </Col>
                :
                <Col>
                  <Label>{t('Voters No')}</Label>

                  <Input
                    type="text"
                    className={i18n.language === 'ar' ? 'form-control float-start' : 'form-control float-end'}
                    placeholder={t('Search') + '...'}
                    id="VotersNo" name="VotersNo"
                    onChange={(e) => handleChange(e)} />
                </Col>
            }
            <Col>
              <Label>{t('Gender')}</Label>
              <Input type="select" name="SexCode" className="form-select" id="gender" onChange={(e) => handleChange(e)} >
                <option value='' default>{t('Select')}</option>
                <option value='1' default>{t('Male')}</option>
                <option value='2' default>{t('Female')}</option>
              </Input>
            </Col>

            <Col>
              <Label>{t('Voter Status')}</Label>
              <Input
                type="select"
                name="VotersStatus"
                value={searchQuery.VotersStatus}
                className="form-select"
                id="voterStatus"
                onChange={handleChange}
              >
                <option value='' default>{t('Select')}</option>
                <option value={true} default>{t('Voted')}</option>
                <option value={false} default>{t('Not voted')}</option>
              </Input>
            </Col>

            <Col className="cis-btn-col">
              <label> &nbsp;</label>
              <div className="refer-voters-btn-wrap">
                <Button onClick={handleSearch} className="cis-width-120">{t('Search')} <i className="ri-search-2-line"></i></Button>
                <Button onClick={handleClear} className="cis-width-120">{t('Clear')} <i className="ri-filter-off-line"></i></Button>

              </div>
            </Col>
          </Row>


          <Row>
            <Col>
              {(isLoading || Load) ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner
                    style={{
                      height: "3rem",
                      width: "3rem",
                    }}
                    className="me-2"
                  >
                    Loading...
                  </Spinner>
                </div>
              ) : (
                // <TableWithCustomPagination
                //   data={data}
                //   columns={columns(columnNames, i18n, t, addReferVoterHandler)}
                //   pagination
                //   paginationServer
                //   onPageChange={handlePageChange}
                //   onPerPageChange={handlePerPageChange}
                //   paginationTotalRows = {referVotersList?.length}
                //   onChangePerRowsPag = {handlePerRowsPage}
                //   onChangePage = {handlePageChange}
                // />

                // <DataTable
                //   data={data}
                //   columns={columns(columnNames, i18n, t, addReferVoterHandler)}
                // />

                <Table
                  data={data}
                  columns={columns(columnNames, i18n, t, addReferVoterHandler, onDeleteClickHandler)}
                />
              )}

            </Col>

            {
              isLoading ?
                (<div> </div>) :
                (<div className="button-container">
                  <Button className="custom-button" onClick={handlePrev}>
                    <i className="ri-arrow-drop-left-line"></i>
                  </Button>

                  <div className="page-class">
                    <div>{referVotersList.page ? referVotersList?.Data?.length ? referVotersList.page : 0 : 0}</div>
                    <div> -of- </div>
                    <div> {referVotersList.Length ? Math.ceil(referVotersList.Length / 100) : 0}</div>
                  </div>

                  <Button className="custom-button" onClick={handleNext}>
                    <i className="ri-arrow-drop-right-line"></i>
                  </Button>

                </div>)
            }


          </Row>
        </Container>
      </div>
      <DeleteModal
        showDelete={showDelete}
        setShowDelete={setShowDelete}
        onDeleteClick={onDeleteClick}
      />
      <ReferVoterModal
        showReferModal={showReferModal}
        setShowReferModal={setShowReferModal}
        onSaveReferClick={onSaveReferClick}
        onAddNumber={RefetchList}
      />
      <EditReferVoterModal
        detailsByCurrentUser={detailsByCurrentUser}
        showEditReferModal={showEditReferModal}
        setShowEditReferModal={setShowEditReferModal}
        onSaveReferClick={onSaveReferClick}
      />
    </React.Fragment>
  );
};

export default ReferVoters;
