export const GET_LOCALIZATION = "GET_LOCALIZATION";
export const GET_LOCALIZATION_SUCCESS = "GET_LOCALIZATION_SUCCESS";
export const GET_LOCALIZATION_FAIL = "GET_LOCALIZATION_FAIL";


export const GET_LOCALIZATION_TABLE_COLUMN_NAMES = "GET_LOCALIZATION_TABLE_COLUMN_NAMES"
export const GET_LOCALIZATION_TABLE_COLUMN_NAMES_SUCCESS = "GET_LOCALIZATION_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_LOCALIZATION_TABLE_COLUMN_NAMES_FAIL = "GET_LOCALIZATION_TABLE_COLUMN_NAMES_FAIL"

export const ADD_LOCALIZATION = "ADD_LOCALIZATION"
export const ADD_LOCALIZATION_SUCCESS = "ADD_LOCALIZATION_SUCCESS"
export const ADD_LOCALIZATION_FAIL = "ADD_LOCALIZATION_FAIL"

export const UPDATE_LOCALIZATION = "UPDATE_LOCALIZATION"
export const UPDATE_LOCALIZATION_SUCCESS = "UPDATE_LOCALIZATION_SUCCESS"
export const UPDATE_LOCALIZATION_FAIL = "UPDATE_LOCALIZATION_FAIL"

export const DELETE_LOCALIZATION = "DELETE_LOCALIZATION"
export const DELETE_LOCALIZATION_SUCCESS = "DELETE_LOCALIZATION_SUCCESS"
export const DELETE_LOCALIZATION_FAIL = "DELETE_LOCALIZATION_FAIL"

export const GET_SCREENS = "GET_SCREENS"
export const GET_SCREENS_SUCCESS = "GET_SCREENS_SUCCESS"
export const GET_SCREENS_FAIL = "GET_SCREENS_FAIL"

export const GET_DEVICES = "GET_DEVICES"
export const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS"
export const GET_DEVICES_FAIL = "GET_DEVICES_FAIL"

export const ON_ACTIVATE_DEACTIVATE_LOCALIZATION = "ON_ACTIVATE_DEACTIVATE_LOCALIZATION"
export const ON_ACTIVATE_DEACTIVATE_LOCALIZATION_SUCCESS = "ON_ACTIVATE_DEACTIVATE_LOCALIZATION_SUCCESS"
export const ON_ACTIVATE_DEACTIVATE_LOCALIZATION_FAIL = "ON_ACTIVATE_DEACTIVATE_LOCALIZATION_FAIL"

export const GET_TRANSLATION = "GET_TRANSLATION"
export const GET_TRANSLATION_SUCCESS = "GET_TRANSLATION_SUCCESS"
export const GET_TRANSLATION_FAIL = "GET_TRANSLATION_FAIL"

