module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
   API_URL: "https://us-central1-kwelections.cloudfunctions.net",  // Production
   // API_URL: "http://127.0.0.1:5001/kwelections/us-central1", // local
  }
};


 