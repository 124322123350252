import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Input, InputGroup, InputGroupText, Row, Spinner } from "reactstrap";
import { uploadCandidateProfile } from "../../../store/actions";
// import SelectDropDown from "../Select-DropDown/SelectDropDown";



// ************* MODAL FORM ****************
const AddCandidatesForm = ({ onSelectHandler, onChangeHandler, countryData, labels, classRow, isAddOrEdit, onTranslateClickHandler, show }) => {
  const { i18n, t } = useTranslation();
  const [formData, setFormData] = useState({
    ScreenID: null,
    ScreenNameEnglish: '',
    ScreenNameArabic: '',
    LabelNameEnglish: '',
    LabelNameArabic: '',
    LabelKey: ''
  });
  const [lang, setLang] = useState();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [profile, setProfile] = useState(null)
  const dispatch = useDispatch();

  const { Elections, Circles, isLoading } = useSelector((state) => ({
    Elections: state.Elections.elections,
    Circles: state.Circles.circlesByElectionID,
    isLoading: state.Candidates.isLoading
  }));

  const uploadPhoto = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    console.log('file: ', file);
    const formData = new FormData();
    formData.append("Profile", file);
    dispatch(uploadCandidateProfile({ formData }))

  }
 

  const onOtherClickHandler = () => {
    setShouldUpdate(!shouldUpdate)
  }

  useEffect(() => {
    setFormData(classRow);
  }, [classRow])


  useEffect(() => {
    if (show) {
      setLang('')
    }
  }, [show]);


  return (


    <Row>
      {
        labels.map((items) => {
          switch (items?.fieldName || items?.labelName) {

            case 'text-box':
              return (
                <Col key={items?.id} sm={6}>
                  <div className="mb-3">
                    <label htmlFor={items?.labelName} className="col-form-label">{t(items?.labelName)}</label>

                    <div className="lang-change-wrap">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder={t(items.labelName)}
                        defaultValue={formData[items?.value]}
                        name={items?.name} disabled={items?.name === 'LabelKey' && isAddOrEdit === 'isEdit' ? true : false}
                        onChange={(e) => onChangeHandler(e)} id={items?.labelName}
                        required={isAddOrEdit !== "isEdit"}
                      />
                    </div>
                  </div>
                </Col>
              );

            case 'mobile-number':
              return (
                <Col key={items?.id} sm={6}>
                  <div className="mb-3">
                    <label htmlFor={items?.labelName} className="col-form-label">{t(items?.labelName)}</label>

                    <div className="lang-change-wrap">
                      <InputGroup>
                        <InputGroupText>+965</InputGroupText>
                        <Input
                          placeholder={t(items?.labelName)}
                          defaultValue={(isAddOrEdit === 'isEdit' && formData[items?.value]) ? formData[items?.value].replace("+965", "") : ""}
                          name={items?.name}
                          type="number"
                          maxLength={8}
                          onChange={(e) => onChangeHandler(e)}
                          onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 8); // Limit the input to 8 characters
                          }}

                          id={items?.labelName}
                          required={isAddOrEdit !== "isEdit"}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </Col>
              );

            case 'number-box':
              return (
                <Col key={items?.id} sm={6}>
                  <div className="mb-3">
                    <label htmlFor={items?.labelName} className="col-form-label">{t(items?.labelName)}</label>

                    <div className="lang-change-wrap">
                      <Input
                        type="number"
                        className="form-control"
                        placeholder={t(items.labelName)}
                        defaultValue={formData[items?.value]}
                        name={items?.name} disabled={items?.name === 'LabelKey' && isAddOrEdit === 'isEdit' ? true : false}
                        onChange={(e) => onChangeHandler(e)}
                        id={items?.labelName}
                        required
                      />
                    </div>
                  </div>
                </Col>
              );

            case 'election-drop-down':
              return (
                <Col key={items?.id} sm={6}>
                  <div className="mb-3">
                    <label htmlFor="elections" className="col-form-label">{t(items?.labelName)}</label>
                    <select className="form-control" id={items.labelName} defaultValue={classRow[items?.value]} disabled={isAddOrEdit === 'isEdit' ? true : false} name={items?.name} onChange={(e) => onSelectHandler(e)}>
                      <option value="">{t('Choose election')}</option>
                      {
                        Elections.map((item) => {
                          return (
                            <option key={item?._id} value={item?._id}>{i18n?.language === 'en' ? item?.ElectionNameEnglish : item?.ElectionNameArabic}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                </Col>);

            case 'circles-drop-down':
              return (
                <Col key={items?.id} sm={6}>
                  <div className="mb-3">
                    <label htmlFor="circles" className="col-form-label">{t(items?.labelName)}</label>
                    {
                      isAddOrEdit === 'isAdd' ?
                        (<select className="form-control" id={items.labelName} defaultValue={classRow[items?.value]} name={items?.name} onChange={(e) => onChangeHandler(e)}>
                          <option value="">{t('Choose circle')}</option>
                          {
                            Circles.map((item) => {
                              return (
                                <option key={item?._id} value={item?._id}>{i18n?.language === 'en' ? item?.CircleNameEnglish : item?.CircleNameArabic}</option>
                              )
                            })
                          }
                        </select>) : (<Input type="text" defaultValue={i18n?.language === 'en' ? classRow['CircleNameEnglish'] : classRow['CircleNameArabic']} disabled={isAddOrEdit === 'isEdit' ? true : false} />)
                    }

                  </div>
                </Col>
              );

            case 'password':
              return (
                <Col key={items?.id} sm={6}>
                  <div className="mb-3">
                    <label htmlFor={items?.labelName} className="col-form-label">{t(items?.labelName)}</label>

                    <div className="lang-change-wrap">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder={t(items.labelName)}
                        defaultValue={formData[items?.value]}
                        name={items?.name} disabled={items?.name === 'LabelKey' && isAddOrEdit === 'isEdit' ? true : false}
                        onChange={(e) => onChangeHandler(e)} id={items?.labelName}
                        required={isAddOrEdit !== "isEdit"}
                      />
                    </div>
                  </div>
                </Col>
              );

            case 'check-box':
              return (

                // <div class="col"><div class="mb-3"><label for="make as Admin Booth" class="col-form-label make-admin-booth"><input name="admin_booth" id="make as Admin Booth" type="checkbox" class="form-control form-check-input"> make as Admin Booth</label></div></div>
                <Col>
                  <div className="mb-3">
                    <label htmlFor={items?.labelName} className="col-form-label make-admin-booth">{t(items?.labelName)}</label>

                    <Input
                      type="checkbox"
                      name="admin_booth "
                      className="form-control form-check-input"
                      onChange={(e) => { onChangeHandler(e) }}
                      id={items?.labelName}
                    />

                  </div>
                </Col>
              );

            case "profile":
              return (
                  <Col key={items?.id} sm={6}>
                    <div className="mb-3">
                      <label htmlFor={items?.labelName} className="col-form-label">
                        {t(items?.labelName)}
                      </label>

                      <div className="lang-change-wrap profile-input">

                        <Input
                          type="file"
                          className="form-control"
                          name={items?.name}
                          disabled={
                            items?.name === "LabelKey" && isAddOrEdit === "isEdit"
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            uploadPhoto(e)
                          }}
                          id={items?.labelName}
                          required={isAddOrEdit !== "isEdit"}
                          accept="image/*" // Ensure only image files can be selected
                        />

                      </div>
                    </div>
                  </Col>
              );


            default:
              return null
          }
        })
      }
    </Row>
  )
}

export default AddCandidatesForm