export const GET_BOOTHVOTERS = "GET_BOOTHVOTERS";
export const GET_BOOTHVOTERS_SUCCESS = "GET_BOOTHVOTERS_SUCCESS";
export const GET_BOOTHVOTERS_FAIL = "GET_BOOTHVOTERS_FAIL";

export const GET_BOOTHUSER_DETAIL = "GET_BOOTHUSER_DETAIL";
export const GET_BOOTHUSER_DETAIL_SUCCESS = "GET_BOOTHUSER_DETAIL_SUCCESS";
export const GET_BOOTHUSER_DETAIL_FAIL = "GET_BOOTHUSER_DETAIL_FAIL";

export const GET_CLASS_BOOTHVOTERS = "GET_CLASS_BOOTHVOTERS";
export const GET_CLASS_BOOTHVOTERS_SUCCESS = "GET_CLASS_BOOTHVOTERS_SUCCESS";
export const GET_CLASS_BOOTHVOTERS_FAIL = "GET_CLASS_BOOTHVOTERS_FAIL";

export const GET_BOOTHVOTERS_TABLE_COLUMN_NAMES = "GET_BOOTHVOTERS_TABLE_COLUMN_NAMES"
export const GET_BOOTHVOTERS_TABLE_COLUMN_NAMES_SUCCESS = "GET_BOOTHVOTERS_TABLE_COLUMN_NAMES_SUCCESS"
export const GET_BOOTHVOTERS_TABLE_COLUMN_NAMES_FAIL = "GET_BOOTHVOTERS_TABLE_COLUMN_NAMES_FAIL"

export const ADD_BOOTHVOTERS = "ADD_BOOTHVOTERS"
export const ADD_BOOTHVOTERS_SUCCESS = "ADD_BOOTHVOTERS_SUCCESS"
export const ADD_BOOTHVOTERS_FAIL = "ADD_BOOTHVOTERS_FAIL"

export const UPDATE_BOOTHVOTERS = "UPDATE_BOOTHVOTERS"
export const UPDATE_BOOTHVOTERS_SUCCESS = "UPDATE_BOOTHVOTERS_SUCCESS"
export const UPDATE_BOOTHVOTERS_FAIL = "UPDATE_BOOTHVOTERS_FAIL"

export const DELETE_BOOTHVOTERS = "DELETE_BOOTHVOTERS"
export const DELETE_BOOTHVOTERS_SUCCESS = "DELETE_BOOTHVOTERS_SUCCESS"
export const DELETE_BOOTHVOTERS_FAIL = "DELETE_BOOTHVOTERS_FAIL"

export const ON_ACTIVATE_DEACTIVATE_BOOTHVOTERS = "ON_ACTIVATE_DEACTIVATE_BOOTHVOTERS"
export const ON_ACTIVATE_DEACTIVATE_BOOTHVOTERS_SUCCESS = "ON_ACTIVATE_DEACTIVATE_BOOTHVOTERS_SUCCESS"
export const ON_ACTIVATE_DEACTIVATE_BOOTHVOTERS_FAIL = "ON_ACTIVATE_DEACTIVATE_BOOTHVOTERS_FAIL"

export const ON_ACTIVATE_BOOTHVOTERS = "ON_ACTIVATE_BOOTHVOTERS"
export const ON_ACTIVATE_BOOTHVOTERS_SUCCESS = "ON_ACTIVATE_BOOTHVOTERS_SUCCESS"
export const ON_ACTIVATE_BOOTHVOTERS_FAIL = "ON_ACTIVATE_BOOTHVOTERS_FAIL"


