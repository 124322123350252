export const GET_ELECTION_CIRCLE = "  GET_ELECTION_CIRCLE";
export const GET_ELECTION_CIRCLE_SUCCESS = "  GET_ELECTION_CIRCLE_SUCCESS";
export const GET_ELECTION_CIRCLE_FAIL = "  GET_ELECTION_CIRCLE_FAIL";

export const CSV_BEING_UPLOADED = "CSV_BEING_UPLOADED";

export const GET_UPLOAD_VOTERS_TABLE_COLUMN_NAMES =
  "GET_UPLOAD_VOTERS_TABLE_COLUMN_NAMES";
export const GET_UPLOAD_VOTERS_TABLE_COLUMN_NAMES_SUCCESS =
  "GET_UPLOAD_VOTERS_TABLE_COLUMN_NAMES_SUCCESS";
export const GET_UPLOAD_VOTERS_TABLE_COLUMN_NAMES_FAIL =
  "GET_UPLOAD_VOTERS_TABLE_COLUMN_NAMES_FAIL";

export const ADD_UPLOAD_VOTERS = "ADD_UPLOAD_VOTERS";
export const ADD_UPLOAD_VOTERS_SUCCESS = "ADD_UPLOAD_VOTERS_SUCCESS";
export const ADD_UPLOAD_VOTERS_FAIL = "ADD_UPLOAD_VOTERS_FAIL";

export const ADD_ELECTION_CIRCLE = "ADD_ELECTION_CIRCLE";
export const ADD_ELECTION_CIRCLE_SUCCESS = "ADD_ELECTION_CIRCLE_SUCCESS";
export const ADD_ELECTION_CIRCLE_FAIL = "ADD_ELECTION_CIRCLE_FAIL";

export const UPDATE_UPLOAD_VOTERS = "UPDATE_UPLOAD_VOTERS";
export const UPDATE_UPLOAD_VOTERS_SUCCESS = "UPDATE_UPLOAD_VOTERS_SUCCESS";
export const UPDATE_UPLOAD_VOTERS_FAIL = "UPDATE_UPLOAD_VOTERS_FAIL";

export const DELETE_UPLOAD_VOTERS = "DELETE_UPLOAD_VOTERS";
export const DELETE_UPLOAD_VOTERS_SUCCESS = "DELETE_UPLOAD_VOTERS_SUCCESS";
export const DELETE_UPLOAD_VOTERS_FAIL = "DELETE_UPLOAD_VOTERS_FAIL";

export const ON_ACTIVATE_DEACTIVATE_UPLOAD_VOTERS =
  "ON_ACTIVATE_DEACTIVATE_UPLOAD_VOTERS";
export const ON_ACTIVATE_DEACTIVATE_UPLOAD_VOTERS_SUCCESS =
  "ON_ACTIVATE_DEACTIVATE_UPLOAD_VOTERS_SUCCESS";
export const ON_ACTIVATE_DEACTIVATE_UPLOAD_VOTERS_FAIL =
  "ON_ACTIVATE_DEACTIVATE_UPLOAD_VOTERS_FAIL";
